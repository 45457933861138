import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { useCallback, useState } from "react"
import { useLandingContext } from "src/contexts/LandingContext"
import { Button } from "@arq-apps/ui"
import { ENDPOINTS } from "@arq-apps/util";

export function SyncExport() {
  const [loading, setLoading] = useState<boolean>(false);
  const { dmpClient } = useDotnetClient();
  const { appId, appKey } = useLandingContext();

  const onExportToFile = useCallback(() => {
    if (!appId) return
    if (!dmpClient) return
    //TODO - toast for failure on these conditions

    setLoading(true);

    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const file = `${ENDPOINTS.DOTNET}/rest/app-settings/download/${appId}`;
    
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem("token"));

    let filename = `dmpexport-${appKey || appId}-${(new Date()).toISOString().replace(/[^A-Za-z0-9]+/g,'').slice(0, -1)}.json`;

    fetch(file, { headers })
        .then(async (response) => {
          // this header business doesn't seem to work, so currently the backup filename above is used
          const cdHeader = response.headers.get("Content-Disposition");
          if (cdHeader !== null)
          {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(cdHeader);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const blob = await response.blob();
          const objectUrl = window.URL.createObjectURL(blob);
          anchor.setAttribute('href', objectUrl);
          anchor.setAttribute('download', filename);
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
  }, [])

  return (
    <div className="ux-page manage">
      <div className="panel">
        <Button text="Export To file" onClick={onExportToFile} disabled={loading} />
      </div>
    </div>
  )
}