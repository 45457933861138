import "./Field.scss";
import * as g from "./Field.generated";
import * as types from "@arq-apps/generated";
import {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ApolloCache, useApolloClient } from "@apollo/client";
import { useContext } from "src/hooks";
import { toast } from "react-toastify";
import { ENDPOINTS } from "@arq-apps/util";
import { TextFieldFragment } from "../../ux/TextField/TextField.generated";
import * as Checkbox from '@radix-ui/react-checkbox';
import { Button, CheckIcon, IconButton, Icon } from "@arq-apps/ui";
import { DateTimeField, Select, TabSelect, Toggle } from "@arq-apps/ux";
import Tooltip from "src/ux/Tooltip/Tooltip";
import { useLandingContext } from "src/contexts/LandingContext";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { DateFormField } from "src/ux/DateFormField/DateFormField";
import { MessagesStorageStorageFileType } from "@arq-apps/dotnet";
import { ComboBox } from "../ComboBox/ComboBox"
import DOMPurify from "isomorphic-dompurify";

export function fieldToInput(field: g.FieldFragment): types.FieldInput {
  switch (field.__typename) {
    case "TextField":
      return {
        id: field.id,
        tag: types.FieldTag.TextField,
        text: field.localText ?? field.text,
      };
    case "NumberField":
      return {
        id: field.id,
        tag: types.FieldTag.NumberField,
        value: field.localValue ?? field.value,
      };
    case "SelectField":
      const selection = field.localSelection ?? field.selection;
      return {
        id: field.id,
        tag: types.FieldTag.SelectField,
        selection: selection
          ? {
            value: selection.value,
            label: selection.label,
          }
          : undefined,
      };
    case "MultiSelectField":
      const selections = field.localSelections ?? [];
      return {
        id: field.id,
        tag: types.FieldTag.MultiSelectField,
        // TODO `select all`
        selections: selections.map((selection) => ({
          value: selection.value,
          label: selection.label,
        })),
      };
    case "BooleanField":
      return {
        id: field.id,
        tag: types.FieldTag.BooleanField,
        state: field.localState ?? field.state
      }
    case "DateField":
      return {
        id: field.id,
        tag: types.FieldTag.DateField,
        date: field.localDate ?? field.date
      }
    case "DateTimeField":
      return {
        id: field.id,
        tag: types.FieldTag.DateTimeField,
        dateTime: field.localDateTime ?? field.dateTime
      }
  }
  console.warn("Field::fieldToInput encountered unknown field type", field);
  return {
    id: field.id,
    tag: types.FieldTag.UnknownField, // unknown
  };
}

type FieldProps = g.FieldFragment & {
  denseFormat?: boolean | null | undefined;
  updateFilters?: (id: string, option: types.SelectOption | null) => void
}

export function Field(props: FieldProps) {
  const classes = ["field", props.denseFormat ? "-dense" : ""].filter(Boolean).join("");
  return (
    <div className={classes}>
      <div className="label-container">
        <div className="label">
          {props.label ?? props.id}
        </div>
        <div className="tooltip">
          {props.information &&
            <Tooltip message={props.information} >
              <Icon icon="information-outline" />
            </Tooltip>}
        </div>
      </div>
      {!props.denseFormat && props.description && (
        <div className="description">{props.description}</div>
      )}
      <div className={`control ${props.__typename} ${(props as any)?.style == "tabs" ? "tabs" : ''}`}>
        <FieldControl field={props} updateFilters={props.updateFilters} />
      </div>
    </div>
  );
}

// TODO @RM - should these local names be using an enum like FieldTag does
function fieldFragmentToLocalValueFieldname(field: g.FieldFragment): string {
  switch (field.__typename) {
    case "TextField":
      return "localText";
    case "NumberField":
      return "localValue";
    case "SelectField":
      return "localSelection";
    case "MultiSelectField":
      return "localSelections";
    case "BooleanField":
      return "localState";
    case "DateField":
      return "localDate";
    case "DateTimeField":
      return "localDateTime";
  }
  console.warn("InteractPane::filterFragmentToLocalValueFieldname encountered unknown filter type", field);
  return "unknown";
}

export function clearLocalFieldValue(cache: ApolloCache<object>, field: g.FieldFragment) {
  cache.evict({
    id: cache.identify({ __typename: field.__typename, id: field.id }),
    fieldName: fieldFragmentToLocalValueFieldname(field)
  })
}

export function resetLocalFieldValue(cache: ApolloCache<object>, field: g.FieldFragment) {
  switch (field.__typename) {
    case "TextField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditTextFieldFragmentDoc,
        data: {
          __typename: "TextField",
          id: field.id,
          localText: field.text
        }
      })
      return
    case "NumberField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditNumberFieldFragmentDoc,
        data: {
          __typename: "NumberField",
          id: field.id,
          localvalue: field.value
        }
      })
      return
    case "SelectField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditSelectFieldFragmentDoc,
        data: {
          __typename: "SelectField",
          id: field.id,
          localSelection: field.selection ?? null
        }
      })
      return
    case "MultiSelectField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditMultiSelectFieldFragmentDoc,
        data: {
          __typename: "MultiSelectField",
          id: field.id,
          localSelections: field.selections ?? null
        }
      })
      return
    case "BooleanField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditBooleanFieldFragmentDoc,
        data: {
          __typename: "BooleanField",
          id: field.id,
          localState: field.state
        }
      })
      return
    case "DateField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditDateFieldFragmentDoc,
        data: {
          __typename: "DateField",
          id: field.id,
          localDate: field.date
        }
      })
      return
    case "DateTimeField":
      cache.writeFragment({
        id: cache.identify({ __typename: field.__typename, id: field.id }),
        fragment: g.EditDateTimeFieldFragmentDoc,
        data: {
          __typename: "DateTimeField",
          id: field.id,
          localDateTime: field.dateTime
        }
      })
      return
  }
  console.warn("Field::resetField encountered unknown filter typename", field);
  return "unknown";
}

interface FieldControlProps {
  field: g.FieldFragment,
  updateFilters?: (id: string, option: types.SelectOption | null) => void
}

function FieldControl(props: FieldControlProps) {
  const field = props.field;
  const client = useApolloClient();

  const editTextField = useCallback((id: string, text: string | null) => {
    console.debug(`edit text field '${id}'; set new value '${text}'`);
    client.writeFragment<g.EditTextFieldFragment>({
      id: client.cache.identify({ __typename: "TextField", id }),
      fragment: g.EditTextFieldFragmentDoc,
      data: {
        __typename: "TextField",
        id,
        // text: 'New text',
        localText: text,
      },
    });
  }, []);

  const editNumberField = useCallback((id: string, value: number | null) => {
    console.debug(`edit number field '${id}'; set new value '${value}'`);
    client.writeFragment<g.EditNumberFieldFragment>({
      id: client.cache.identify({ __typename: "NumberField", id }),
      fragment: g.EditNumberFieldFragmentDoc,
      data: {
        __typename: "NumberField",
        id,
        // value: 0,  // new value
        localValue: value,
      },
    });
  }, []);

  const selectOption = useCallback((
    id: string,
    option: types.SelectOption | null,
  ) => {
    console.debug(`SelectField '${id}' > select option`, option);
    client.cache.writeFragment<g.EditSelectFieldFragment>({
      id: client.cache.identify({ __typename: "SelectField", id }),
      fragment: g.EditSelectFieldFragmentDoc,
      data: {
        __typename: "SelectField",
        id,
        localSelection: option,
      },
    });
  }, []);

  const selectClear = (id: string) => {
    console.debug(`SelectField '${id}' > select clear`);
    client.cache.writeFragment<g.EditSelectFieldFragment>({
      id: client.cache.identify({ __typename: "SelectField", id }),
      fragment: g.EditSelectFieldFragmentDoc,
      data: {
        __typename: "SelectField",
        id,
        localSelection: null,
      },
    });
  }

  const toggleOption = useCallback((id: string, option: types.SelectOption) => {
    console.debug(`MultiSelectField '${id}' > toggle option`, option);
    client.cache.updateFragment<g.EditMultiSelectFieldFragment>(
      {
        id: client.cache.identify({ __typename: "MultiSelectField", id }),
        fragment: g.EditMultiSelectFieldFragmentDoc,
        // data: {
        //   selections: selections,
        //   localSelections: [],
        // },
      },
      (data) => {
        const currentSelections = data?.localSelections ?? [];
        const filtered = currentSelections.filter(
          (it) => it.value !== option.value
        );
        return {
          __typename: "MultiSelectField",
          id,
          ...data,
          localSelections:
            filtered.length < currentSelections.length
              ? // option was filtered out
              filtered
              : // option must be added
              [...filtered, option],
        };
      }
    );
  }, []);

  const multiSelectAll = useCallback((id: string, options: types.SelectOption[]) => {
    console.debug(`MultiSelectField '${id}' > select all`);
    client.cache.writeFragment<g.EditMultiSelectFieldFragment>({
      id: client.cache.identify({ __typename: "MultiSelectField", id }),
      fragment: g.EditMultiSelectFieldFragmentDoc,
      data: {
        __typename: "MultiSelectField",
        id,
        localSelections: options
      }
    })
  }, [])

  const multiClearAll = useCallback((id: string) => {
    console.debug(`MultiSelectField '${id}' > clear all`);
    client.cache.writeFragment<g.EditMultiSelectFieldFragment>({
      id: client.cache.identify({ __typename: "MultiSelectField", id }),
      fragment: g.EditMultiSelectFieldFragmentDoc,
      data: {
        __typename: "MultiSelectField",
        id,
        localSelections: []
      }
    })
  }, [])

  const onMultiAccept = useCallback((id: string, options: types.SelectOption[]) => {
    console.debug(`MultiSelectField '${id}' > select all`);
    client.cache.writeFragment<g.EditMultiSelectFieldFragment>({
      id: client.cache.identify({ __typename: "MultiSelectField", id }),
      fragment: g.EditMultiSelectFieldFragmentDoc,
      data: {
        __typename: "MultiSelectField",
        id,
        localSelections: options
      }
    })
  }, [])

  const booleanToggle = useCallback((id: string, state: boolean | null | undefined) => {
    console.debug(`edit boolean field '${id}'; set new state '${state}'`);
    client.writeFragment<g.EditBooleanFieldFragment>({
      id: client.cache.identify({ __typename: "BooleanField", id }),
      fragment: g.EditBooleanFieldFragmentDoc,
      data: {
        __typename: "BooleanField",
        id,
        localState: state,
      }
    });
  }, []);

  const changeDate = useCallback((id: string, date: string | null) => {
    console.debug(`edit date field '${id}'; set new value '${date}'`);
    client.writeFragment<g.EditDateFieldFragment>({
      id: client.cache.identify({ __typename: "DateField", id }),
      fragment: g.EditDateFieldFragmentDoc,
      data: {
        __typename: "DateField",
        id,
        localDate: date,
      },
    });
  }, []);

  const changeDateTime = useCallback((id: string, dateTime: string | null) => {
    console.debug(`edit date time field '${id}'; set new value '${dateTime}'`);
    client.writeFragment<g.EditDateTimeFieldFragment>({
      id: client.cache.identify({ __typename: "DateTimeField", id }),
      fragment: g.EditDateTimeFieldFragmentDoc,
      data: {
        __typename: "DateTimeField",
        id,
        localDateTime: dateTime,
      },
    });
  }, []);

  switch (field.__typename) {
    case "TextField":
      return <TextField {...field} onChange={editTextField} />;
    case "NumberField":
      return <NumberField {...field} onChange={editNumberField} />;
    case "SelectField":
      if (field.style === types.SelectStyle.Tabs && (field.options?.length ?? 0) <= SELECT_OPTIONS_THRESHOLD) {
        return <TabSelect {...field} onOptionSelected={selectOption} />
      }
      const selection = field.localSelection ?? field.selection ?? undefined
      const selections = selection ? [selection] : []
      return <ComboBox
        id={field.id}
        placeholder={field.label ?? "Select"}
        options={field.options ?? []}
        multiselect={false}
        selections={selections}
        onSelectOption={selectOption}
      />
    case "MultiSelectField":
      return <ComboBox
        id={field.id}
        placeholder={field.label ?? "Select"}
        options={field.options ?? []}
        multiselect
        selections={field.localSelections ?? field.selections ?? []}
        onAccept={onMultiAccept}
      />
    case "UploadFileField":
      return <UploadFileField {...field} />;
    case "DownloadFileField":
      return <DownloadFileField {...field} />;
    case "BooleanField":
      return <BooleanField {...field} onChange={booleanToggle} />;
    case "DateField":
      return <DateFormField {...field} editable={true} onChange={changeDate} />;
    case "DateTimeField":
      return <DateTimeField {...field} onChange={changeDateTime} />;
    // TODO additional field types
  }
  const message = `Field type ${field.__typename} not yet supported.`;
  console.warn(message, field);
  return (
    <div>
      <span>WIP</span>
      {message}
    </div>
  );
}

interface NumberFieldProps extends g.NumberFieldFragment {
  onChange: (id: string, value: number | null) => void;
}

// TODO split Field && Control to generalise
function NumberField(props: NumberFieldProps) {
  // TODO @RM - change to use controlled value from client cache
  const [text, setText] = useState<string>(`${props.value ?? ""}`);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setText(`${props.value ?? ""}`);
    setError(null);
  }, [props.value]);

  const handleChange = useCallback((value: string, error: string | null) => {
    if (value && !Number.isNaN(value) && !Number.isNaN(parseFloat(value))) {
      props.onChange(props.id, parseFloat(value));
    } else {
      props.onChange(props.id, null);
    }
    setText(value);
    setError(error);
  }, []);

  return (
    <div>
      <FieldInput value={text} onChange={handleChange} />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

interface TextFieldProps extends TextFieldFragment {
  onChange: (id: string, text: string | null) => void;
}

// TODO split Field && Control to generalise
function TextField(props: TextFieldProps) {
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback((value: string, error: string | null) => {
    props.onChange(props.id, value);
    setError(error);
  }, []);

  return (
    <div>
      <FieldInput value={props.localText ?? props.text ?? ""} onChange={handleChange} />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

interface SelectOptionProps {
  fieldId: string;
  selected: boolean;
  option: types.SelectOption;
  onClick: (id: string, option: types.SelectOption) => void;
  showCheckbox: boolean;
}

const SelectOption = memo(function SelectOption(props: SelectOptionProps) {
  const handleClick = useCallback(() => {
    props.onClick(props.fieldId, props.option);
  }, [props.fieldId, props.option.value]);

  return (
    <div onClick={handleClick} className={`option ${props.selected ? "selected" : ""}`} >
      {props.showCheckbox && <Checkbox.Root
        checked={props.selected}
      >
        <Checkbox.CheckboxIndicator>
          <CheckIcon />
        </Checkbox.CheckboxIndicator>
      </Checkbox.Root>}
      <div className="label">{props.option.label ?? props.option.value}</div>
    </div>
  );
});

const SELECT_OPTIONS_THRESHOLD = 16; // TODO control from backend (platform settings?)
interface SelectFieldProps extends g.SelectFieldFragment {
  onOptionClicked: (id: string, option: types.SelectOption) => void;
  onSelectClear: (id: string) => void;
}

// TODO split Field && Control to generalise
export function SelectField(props: SelectFieldProps) {
  const [selected, setSelected] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelected(
      (props.localSelection?.value ?? props.selection?.value ?? "")
    );
    setSearch(
      (props.localSelection?.label ?? props.selection?.label ?? "")
    )
  }, [props.localSelection, props.selection]);

  const handleSearch = useCallback((text: string, error: string | null) => {
    setSearch(text);
  }, []);

  const filteredOptions: types.SelectOption[] = useMemo(
    () =>
      props.options?.filter(
        (opt) =>
          opt.value.toLowerCase().includes(search.toLowerCase()) ||
          opt.label?.toLowerCase()?.includes(search.toLowerCase())
      ) ?? [],
    [props.options, search]
  );

  const showTabs = useMemo(
    () =>
    (props.style === types.SelectStyle.Tabs &&
      (props.options?.length ?? 0) <= SELECT_OPTIONS_THRESHOLD
    ),
    [props.style, props.options?.length, SELECT_OPTIONS_THRESHOLD]
  );

  const handleDropdownClick = () => {
    if (optionsOpen) {
      setSearch(props.localSelection?.label ?? props.selection?.value ?? "")
      setOptionsOpen(false)
    } else {
      setSearch("")
      setOptionsOpen(true)
    }
  }

  return (
    <div className={showTabs ? "tabs" : "dropdown"}>
      {!showTabs && <div className="field-input-wrapper">
        <FieldInput label={props.label || ''} value={search} onChange={handleSearch} />
        <IconButton icon="down-chevron-outline" onClick={handleDropdownClick} />
      </div>}
      {/* TODO @RM - different components for tab and dropdown multi select */}
      {(optionsOpen || showTabs) && <div className={"options"}>
        {!showTabs && <div className="option" onClick={() => props.onSelectClear(props.id)}>
          <div className="label action">Clear All</div>
        </div>}
        {filteredOptions.map((option) => (
          <SelectOption
            key={option.value}
            fieldId={props.id}
            selected={selected === option.value}
            option={option}
            onClick={(id, option) => {
              props.onOptionClicked(id, option)
              setOptionsOpen(false)
            }}
            showCheckbox={false}
          />
        ))}
      </div>}
    </div>
  );
}

interface MultiSelectFieldProps extends g.MultiSelectFieldFragment {
  onOptionClicked: (id: string, option: types.SelectOption) => void;
  onSelectAll: (id: string, options: types.SelectOption[]) => void;
  onClearAll: (id: string) => void;
}

// TODO split Field && Control to generalise
export function MultiSelectField(props: MultiSelectFieldProps) {
  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const client = useApolloClient();

  useEffect(() => {
    setSelected(
      (props.localSelections ?? props.selections)?.map((it) => it.value) ?? []
    );
  }, [props.localSelections?.length, props.selections?.length]);

  useEffect(() => {
    client.cache.writeFragment<g.EditMultiSelectFieldFragment>(
      {
        id: client.cache.identify({ __typename: "MultiSelectField", id: props.id }),
        fragment: g.EditMultiSelectFieldFragmentDoc,
        data: {
          id: props.id,
          localSelections: props.selections,
        },
      },)
  }, [props.selections]);

  const handleSearch = useCallback((text: string, error: string | null) => {
    setSearch(text.toLowerCase());
  }, []);

  const filteredOptions: types.SelectOption[] = useMemo(
    () =>
      props.options?.filter(
        (opt) =>
          opt.value.toLowerCase().includes(search) ||
          opt.label?.toLowerCase()?.includes(search)
      ) ?? [],
    [props.options, search]
  );

  const showTabs = useMemo(
    () =>
      (!props.style &&
        (props.options?.length ?? 0) <= SELECT_OPTIONS_THRESHOLD) ||
      props.style === types.SelectStyle.Tabs,
    [props.style, props.options?.length, SELECT_OPTIONS_THRESHOLD]
  );

  const handleSelectAll = useCallback(() => {
    props.onSelectAll(props.id, filteredOptions ?? [])
  }, [props.id, filteredOptions])

  const handleClearAll = useCallback(() => {
    props.onClearAll(props.id)
  }, [props.id])

  const counterFontSize = useMemo(() => {
    const selectionLength = props.localSelections?.length ?? props.selections?.length ?? 0
    if (selectionLength > 999) {
      return "8px"
    }
    if (selectionLength > 99) {
      return "10px"
    }
    return "12px"
  }, [props.localSelections?.length, props.selections?.length])

  const counterValue = useMemo(() =>
    props.localSelections?.length ?? props.selections?.length,
    [props.localSelections ?? props.selections]
  );

  const hasSelection = !!counterValue;
  const counterClassName = hasSelection ? "counter" : "counter-not-selection";

  return (
    <div className={showTabs ? "tabs" : "dropdown"}>
      {!showTabs && <div className="field-input-wrapper">
        <FieldInput value={search} onChange={handleSearch} />
        {hasSelection
          &&
          <div className={counterClassName} style={{ fontSize: `${counterFontSize}` }}>
            {counterValue}
          </div>
        }
        <IconButton icon="down-chevron-outline" onClick={() => setOptionsOpen(!optionsOpen)} />
      </div>}
      {/* TODO @RM - different components for tab and dropdown multi select */}
      <div className={optionsOpen || showTabs ? "options" : "hidden"}>
        {!showTabs && <>
          <div className="option" onClick={handleClearAll}>
            <div className="label action">Clear All</div>
          </div>
          <div className="option" onClick={handleSelectAll}>
            <div className="label action">Select All</div>
          </div>
        </>}
        {filteredOptions.map((option) => (
          <SelectOption
            key={option.value}
            fieldId={props.id}
            selected={selected.includes(option.value)}
            option={option}
            onClick={props.onOptionClicked}
            showCheckbox={!showTabs}
          />
        ))}
      </div>
    </div>
  );
}

type UploadFileFieldProps = g.UploadFileFieldFragment

// TODO split Field && Control to generalise
function UploadFileField(props: UploadFileFieldProps) {
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { appId, projectId } = useContext();
  const LandingContext = useLandingContext();
  const [fileSizeLimit, setFileSizeLimit] = useState<number>(20 * 1024 * 1024);
  const [acceptFiles, setAcceptFiles] = useState<string[]>(['.csv', '.xlsx']);
  const [acceptMimeTypes, setAcceptMimeTypes] = useState<string[]>(['text/csv', '.xlsx']);

  const { dmpClient } = useDotnetClient();

  const handleClick = useCallback(() => {
    uploadRef.current?.click();
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0) ?? null;
    setFile(file);
  }, []);

  const hiddenInputStyle: CSSProperties = useMemo(
    () => ({
      display: "none",
    }),
    []
  );

  const loadFileRequirements = async () => {
    let applicationId = 1;
    if (LandingContext.appId != undefined)
      applicationId = LandingContext.appId;

    dmpClient?.storageApi.postStorageApiStorageFileRequirements(applicationId, 0).then(res => {
      setFileSizeLimit(res.fileSizeLimit);
      setAcceptFiles(res.extensionsAllowed);
      setAcceptMimeTypes(res.mimeTypesAllowed);
    })
  }

  useEffect(() => {
    loadFileRequirements();
  }, [])

  const validateFile = (file: File | null): boolean => {
    if (!file)
      return false;

    if (file.size === 0) {
      toast.error(`File is empty.`);
      return false;
    }

    if (file.size > fileSizeLimit) {
      toast.error(`File size exceeds max size of ${fileSizeLimit / 1024 / 1024} MB`);
      return false;
    }

    const fileName = file?.name || '';
    if (!fileName) {
      toast.error(`Cant determine filename`);
      return false;
    }

    const match = fileName.match(/\.([^\.]+)$/);
    if (match === null || match.length < 2) {
      toast.error(`Cant find extension`);
      return false;
    }

    const fileExt = match[1];
    console.log(fileExt);
    if (acceptFiles.findIndex(x => x === '.' + fileExt) === -1) {
      toast.error(`Invalid file type selected. Must be ${acceptFiles.join('/')}`);
      return false;
    }

    return true;
  }


  useEffect(() => {
    if (!validateFile(file))
      return;

    // TODO fix this when we have a definite numeric ID for the application
    let applicationId = 1;
    if (LandingContext.appId != undefined)
      applicationId = LandingContext.appId;

    const formData = new FormData();
    formData.append("application_id", applicationId.toString());
    formData.append("project_id", (projectId || 0).toString());
    formData.append("template_id", props.templateId ? props.templateId : '');
    formData.append("entity_id", props.entityId ? props.entityId : '');
    formData.append('file', file!, file?.name);

    // TODO! - @ Richard ask Rory
    // dmpClient?.storageApi.postStorageApiUploadFile(formData)

    fetch(`${ENDPOINTS.DOTNET}/rest/storage/upload_file`, {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
      body: formData,
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const errorJson = await resp.json();
          throw new Error(errorJson.Message);
        }

        toast.info(`File upload complete: ${file?.name}`);
      })
      .catch((error) => {
        console.warn("File upload failed: ", error);  // TODO @SI report file upload errors to backend
        toast.error(`File upload failed : ${error}`);
      })

  }, [file?.name]);

  return (
    <div className="upload-file">
      <input
        type="file"
        ref={uploadRef}
        style={hiddenInputStyle}
        onChange={handleChange}
        hidden
        accept={acceptFiles.concat(acceptMimeTypes).join(',')}
      />
      <Button
        text={file?.name || "Choose a file to upload"}
        onClick={handleClick}
      />
    </div>
  );
}

type DownloadFileFieldProps = g.DownloadFileFieldFragment

function DownloadFileField(props: DownloadFileFieldProps) {
  const [downloadSelection, setDownloadSelection] = useState<types.SelectOption | null>();
  const [selectProps, setSelectProps] = useState<types.SelectField | null>();
  const { appId, projectId } = useLandingContext();
  const downloadRef = useRef<HTMLAnchorElement | null>(null);

  const handleClickDotNet = async () => {
    // This handles downloading from .NET. Python provides a "url" as the option "value" with
    // blob_name=<jwt with file guid>&app_id=<appKey>&project_id=<projectId>&query_token=<access jwt>
    // The JWT middle/body part has {"item": "<file guid>"}
    const fileTarget: string = downloadSelection?.value || "";
    const foundSelection = selectProps?.options?.find(x => x.value == fileTarget);
    const filename = foundSelection?.label;

    const pairs = fileTarget.split('&'); // split key/value pairs
    const nameValueBits = pairs[0].split('='); // split first (blob_name) key/value
    const blobName = nameValueBits[1];
    const fileType: MessagesStorageStorageFileType = 0;

    const response = await fetch(`${ENDPOINTS.LANDING}/storage/download_file/${blobName},${appId},${projectId},${fileType}`, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }
    })
    if (response.ok) {
      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      if (downloadRef.current) {
        downloadRef.current.setAttribute('href', objectUrl);
				downloadRef.current.setAttribute('download', filename ?? "filename.txt");
        downloadRef.current.click();
        downloadRef.current.setAttribute('href', "");
      }
    }
    else {
      let errMessage = 'Unknown error';
      if (response.statusText)
        errMessage = response.statusText;
      try {
        const tryJson = await response.json();
        if (tryJson.Message)
          errMessage = tryJson.Message;
      } catch (error) {
        // ignore
      }
      toast.error('Error downloading file: ' + DOMPurify.sanitize(errMessage));
    }
  };

  const selectOption = (id: string, option: types.SelectOption | null) => {
    setDownloadSelection(option)
    if (selectProps) {
      const updateProps = {
        ...selectProps, selection: option
      }
      setSelectProps(updateProps)
    }
  }

  useEffect(() => {
    if (props.selection) {
      setSelectProps(props.selection)
    }
  }, [props.selection])

  return (
    <div>
      {selectProps && (
        <Select {...selectProps} onOptionSelected={selectOption} />
      )}
      <div className="download-button">
        <Button
          text={"Download"}
          onClick={handleClickDotNet}
        />
        <a ref={downloadRef} href="" target="_blank" download
          style={{ display: "hidden" }}
        ></a>
      </div>
    </div>
  );
}

interface BooleanFieldProps extends g.BooleanFieldFragment {
  onChange: (id: string, state: boolean, extra: null) => void;
}

export function BooleanField(props: BooleanFieldProps) {
  return (
    <div className="boolean-toggle">
      <Toggle
        id={props.id}
        label={props.label || props.description || null}
        labelled={false}
        state={props.localState ?? props.state}
        editable={true}
        onChange={props.onChange}
        onChangeExtra={null}
      />
    </div>
  );
}

interface FieldInputProps {
  label?: string;
  value: string;
  onChange: (value: string, error: string | null) => void;
  // validate: (value: string | null) => [value: T | null, error: string | null],
  height?: number;
  // debounceDelay?: number,
}

export function FieldInput(props: FieldInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [cursorIndex, setCursorIndex] = useState<number | null>(0);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    inputRef.current?.select(); // auto-select text on mount
  }, []);

  const style: CSSProperties = useMemo(
    () => ({
      position: "relative", // TODO move to style sheet
      ...(props.height
        ? {
          height: `${props.height}px`,
        }
        : {}),
    }),
    [props.height]
  );

  useEffect(() => {
    if (cursorIndex && inputRef.current) {
      inputRef.current.setSelectionRange(cursorIndex, cursorIndex);
    }
  }, [props.value])

  const classes = ["field-input"].filter(Boolean).join(" ");

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setCursorIndex(event.currentTarget.selectionStart);
    // const newValue = event.target.value;
    // setValue(newValue);
    // const [value, error] = [newValue, null]; // TODO props.validate(value);
    // debounced(value, error);
    props.onChange(event.target.value, null); // TODO validate
  }

  function handleDeleteValue(event: React.MouseEvent<HTMLElement>): void {
    console.debug("Input::handleDeleteValue");
    props.onChange("", null); // TODO validate
    event.preventDefault();
    event.stopPropagation();
  }

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "Enter":
        // event.stopPropagation();
        event.preventDefault(); // default executes handleDeleteValue (button)
        break;
    }
  }, []);

  return (
    <div className={classes} style={style} onKeyDown={handleKeyDown}>
      <input
        ref={inputRef}
        value={props.value}
        onChange={handleChange}
        placeholder={props.label}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <IconButton
        icon={focused ? "circle-delete-fill" : "search-outline"}
        focusable={false}
        onClick={handleDeleteValue}
      />
    </div>
  );
}