import "./Form.scss";
import { useForm } from "react-hook-form";
import {
  Field,
  Input,
  Textarea} from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesApplicationsApplicationRole,
  MessagesApplicationsNewApplicationRole,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { ApplicationRoleItem } from "../ApplicationControl/AppRolesPanel";

export type ApplicationRoleForm = {
  name: string;
  description: string;
};

interface AddApplicationRoleFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedAppRole?: ApplicationRoleItem;
  appId?: number;
}

export const AddApplicationRoleForm = (props: AddApplicationRoleFormProps) => {
  const { dmpClient } = useDotnetClient();
  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit,
    // control
  } = useForm<ApplicationRoleForm>({
    defaultValues: props.selectedAppRole
      ? ({
          name: props.selectedAppRole.name.value,
          description: props.selectedAppRole.description.value,
        } as ApplicationRoleForm)
      : ({
          name: "",
          description: "",
        } as ApplicationRoleForm),
  });

  const onSubmit = (data: ApplicationRoleForm) => {
    if (props.edit) {
      const updatedRole: MessagesApplicationsApplicationRole = {
        id: props.selectedAppRole!.id!,
        applicationId: props.selectedAppRole!.applicationId!,
        teamCount: 0,
        memberCount: 0,
        ...data,
      };

      dmpClient?.applicationsApi
        .postApplicationsApiUpdateApplicationRole(updatedRole)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("App role succesfully updated");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error updating app role");
          console.warn(err);
        })
        .finally(() => {});
    } else {
      const newAppRole: MessagesApplicationsNewApplicationRole = {
        applicationId: props.appId!,
        ...data,
      };

      dmpClient?.applicationsApi
        .putApplicationsApiAddApplicationRole(newAppRole)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("App role succesfully added");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error adding app role");
          console.warn(err);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Name"
          required
          validationState={errors.name ? "error" : "none"}
          validationMessage={errors.name?.message}
        >
          <Input
            {...register("name", {
              required: "Name is required",
              maxLength: 256,
            })}
          />
        </Field>
        <Field
          label="Description"
          validationState={errors.description ? "error" : "none"}
          validationMessage={
            errors.description?.type === "maxLength"
              ? "Max length exceeded"
              : undefined
          }
        >
          <Textarea {...register("description", { maxLength: 256 })} rows={4} />
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
