import "./Form.scss"
import { useForm } from "react-hook-form";
import {
  Field,
  Input,
  makeResetStyles,
  Switch,
  Textarea,
  tokens
} from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { MessagesSubscriptionsNewOrganisation, MessagesSubscriptionsOrganisation, MessagesSubscriptionsOrganisationSummary } from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";

export type OrganisationForm = {
  name: string;
  longName: string;
  description: string;
  active: boolean;
  domain: string;
};

interface AddOrganisationFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedOrg?: MessagesSubscriptionsOrganisationSummary
}

export const AddOrganisationForm = (props: AddOrganisationFormProps) => {
  const { dmpClient } = useDotnetClient();
  const { 
    register,
    formState: { errors  },
    setError,
    reset,
    handleSubmit
    // control
  } = useForm<OrganisationForm>({
    defaultValues: props.selectedOrg ? {
      name: props.selectedOrg.name,
      longName: props.selectedOrg.longName,
      description: props.selectedOrg.description,
      domain: props.selectedOrg.whitelistedDomain
    } : {
      name: "",
      longName: "",
      description: "",
      domain: ""
    },
  });

  const onSubmit = (data: OrganisationForm) => {
    const organisation: MessagesSubscriptionsNewOrganisation = {
      name: data.name,
      longName: data.longName,
      description: data.description,
      whitelistedDomain: data.domain,
    }
    const updatedOrg: MessagesSubscriptionsOrganisation = {
      name: data.name,
      longName: data.longName,
      description: data.description,
      whitelistedDomain: data.domain,
      active: true,
      id: props.selectedOrg?.id ?? 0,
      createdAt: "",
      updatedAt: "",
      isFrozen: false,
      creatorId: ""
    }

    if (props.edit) {
      dmpClient?.subscriptionsApi.postSubscriptionsApiUpdateOrganisation(updatedOrg)
      .then((result) => {
        // console.log(result)
        props.onSubmit()
        toast.success("Item succesfully updated")
        reset()
      })
      .catch((err) => {
        props.onSubmit()
        toast.error("Error updating item")
        console.warn(err);
      })
      .finally(() => {});
    } else {
      dmpClient?.subscriptionsApi.putSubscriptionsApiAddOrganisation(organisation)
      .then((result) => {
        // console.log(result)
        props.onSubmit()
        toast.success("Item succesfully added")
        reset()
      })
      .catch((err) => {
        props.onSubmit()
        toast.error("Error adding item")
        console.warn(err);
      })
      .finally(() => {});
    }
  }

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Name"
          validationState={errors.name ? "error" : "none"}
          validationMessage={errors.name?.message}
          >
          <Input {...register("name", {required: "Name is required", maxLength: 100})} />
        </Field>

        <Field
          label="Long name"
          validationState={errors.longName ? "error" : "none"}
          validationMessage={errors.longName?.message}
          >
          <Input {...register("longName", { maxLength: 128})} />
        </Field>

        <Field
          label="Description"
          validationState={errors.description ? "error" : "none"}
          validationMessage={errors.description?.type === "maxLength" ? "Max length exceeded" : undefined}
        >
          <Textarea {...register("description", { maxLength: 256})} rows={4} />
        </Field>

        <Field
          label="Active"
          validationState={errors.active ? "error" : "none"}
          validationMessage={errors.active?.message}
          >
          <Switch {...register("active")}/>
        </Field>

        <Field
          label="Domain"
          validationState={errors.domain ? "error" : "none"}
          validationMessage={errors.domain?.message}
          >
          <Input {...register("domain", { maxLength: 128})} />
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  )
}