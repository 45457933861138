import { MessagesSubscriptionsOrganisationTeam } from "@arq-apps/dotnet";
import {
  Button,
  createTableColumn,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TableCellLayout,
  TableColumnDefinition,
} from "@fluentui/react-components";
import {
  Navigation24Regular,
  PeopleTeam24Regular,
} from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { DataGridComponent } from "../DataGrid/DataGridComponent";
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent";
import { OrganisationTeamForm } from "../Forms/OrganisationTeamForm";
import { useSearchParams } from "react-router-dom";

export const OrganisationTeamsPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [organisationTeams, setOrganisationTeams] = useState<
    MessagesSubscriptionsOrganisationTeam[]
  >([]);
  const [addOrganisationTeam, setAddOrganisationTeam] = useState(false);
  const [editOrganisationTeam, setEditOrganisationTeam] = useState(false);
  const [selectedOrganisationTeam, setSelectedOrganisationTeam] =
    useState<MessagesSubscriptionsOrganisationTeam>();

  const orgId = parseInt(searchParams.get("orgId") ?? "");
  // const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");

  const fetchTeamsList = async () => {
    dmpClient?.subscriptionsApi
      .getSubscriptionsApiGetOrganisationTeams(orgId, 0)
      .then((result) => {
        setOrganisationTeams(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchTeamsList();
  }, [isDMPClientLoaded]);

  const columns: TableColumnDefinition<MessagesSubscriptionsOrganisationTeam>[] =
    [
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "name",
        compare: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        renderHeaderCell: () => {
          return "Team";
        },
        renderCell: (item) => {
          return (
            <TableCellLayout media={<PeopleTeam24Regular color={"#D04A02"} />}>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  paddingTop: ".5rem",
                  paddingBottom: ".5rem",
                  gap: "5px",
                  lineHeight: "1rem",
                }}
              >
                <span style={{ fontSize: "16px" }}>{item.name}</span>
                <span style={{ color: "#979797", fontSize: "small" }}>
                  {item.description}
                </span>
                <span style={{ color: "#979797", fontSize: "small" }}>
                  {item.application}
                </span>                
              </div>
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "linkedProjectCount",
        compare: (a, b) => {
          return a.linkedProjectCount - b.linkedProjectCount;
        },
        renderHeaderCell: () => {
          return "Projects";
        },
        renderCell: (item) => {
          return (
            <TableCellLayout>
              {item.linkedProjectCount.toString()}
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "activeLinkedProjectCount",
        compare: (a, b) => {
          return a.activeLinkedProjectCount - b.activeLinkedProjectCount;
        },
        renderHeaderCell: () => {
          return "Active projects";
        },
        renderCell: (item) => {
          return (
            <TableCellLayout>
              {item.activeLinkedProjectCount.toString()}
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "linkedUserCount",
        compare: (a, b) => {
          return a.linkedUserCount - b.linkedUserCount;
        },
        renderHeaderCell: () => {
          return "Users";
        },
        renderCell: (item) => {
          return (
            <TableCellLayout>{item.linkedUserCount.toString()}</TableCellLayout>
          );
        },
      }),
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "linkedRoleCount",
        compare: (a, b) => {
          return a.linkedRoleCount - b.linkedRoleCount;
        },
        renderHeaderCell: () => {
          return "Roles";
        },
        renderCell: (item) => {
          return (
            <TableCellLayout>{item.linkedRoleCount.toString()}</TableCellLayout>
          );
        },
      }),
      createTableColumn<MessagesSubscriptionsOrganisationTeam>({
        columnId: "actions",
        renderHeaderCell: () => {
          return "Actions";
        },
        renderCell: (item) => {
          return (
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<Navigation24Regular />} />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setSelectedOrganisationTeam(item);
                      setEditOrganisationTeam(true);
                    }}
                  >
                    Edit team
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          );
        },
      }),
    ];

  return (
    <>
      <DataGridComponent
        items={organisationTeams}
        columns={columns}
        heightAdjustment={90}
        onAdd={() => setAddOrganisationTeam(true)}
      />
      <OverlayDrawerComponent
        open={addOrganisationTeam}
        size="medium"
        setOpen={setAddOrganisationTeam}
        title={"Add project team"}
        content={
          <OrganisationTeamForm
            onSubmit={() => {
              setAddOrganisationTeam(false);
              fetchTeamsList();
            }}
          />
        }
      />
      <OverlayDrawerComponent
        open={editOrganisationTeam}
        size="medium"
        setOpen={setEditOrganisationTeam}
        title={"Edit project team"}
        content={
          <OrganisationTeamForm
            onSubmit={() => {
              setEditOrganisationTeam(false);
              fetchTeamsList();
            }}
            edit
            selectedOrganisationTeam={selectedOrganisationTeam}
          />
        }
      />
    </>
  );
};
