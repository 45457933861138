import { ApolloProvider } from "@apollo/client";
import { Root } from "@arq-apps/ux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { apolloClient } from "./apollo/client";
import { LandingControl } from "./ui";
import { DotnetClientProvider } from "./contexts/DotnetClientContext";
import { LandingContextProvider } from "./contexts/LandingContext";
import { BrandVariants, createLightTheme, FluentProvider, Theme } from "@fluentui/react-components";
import { SubscriptionControl } from "./ui/SubscriptionControl/SubscriptionControl";
import { toast, ToastContainer } from "react-toastify";
import { ImageControl } from "./ui/ImageControl/ImageControl";
import { ApplicationControl } from "./ui/ApplicationControl/ApplicationControl";
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { useLogout } from "./hooks";
import { WorkspaceControl } from "./ui/WorkspaceControl/WorkspaceControl"

const pwcOrangeVariants: BrandVariants = {
  10: "#0A0100",
  20: "#310803",
  30: "#520205",
  40: "#690006",
  50: "#7F0005",
  60: "#950903",
  70: "#A81B01",
  80: "#B92E00",
  90: "#C94000",
  100: "#D75306",
  110: "#E36715",
  120: "#ED7B28",
  130: "#F58F3D",
  140: "#FCA456",
  150: "#FFB975",
  160: "#FFCE9C",
};

const pwcLightTheme: Theme = {
  ...createLightTheme(pwcOrangeVariants),
  colorBrandBackground: pwcOrangeVariants[100],
  colorBrandBackgroundInvertedSelected: pwcOrangeVariants[140],
  fontFamilyBase: "unset",
  lineHeightBase300: "unset",
  
  //  colorNeutralBackground1: "unset"
  //   colorBrandForeground1: pwcOrangeVariants[110],
  //   colorBrandForeground2: pwcOrangeVariants[130]
};

export const App = () => {
  const logout = useLogout();

  const onPrompt = (event?: Event | undefined, idleTimer?: IIdleTimer | undefined) => {
    // Fire a Modal Prompt
    toast.warning(`No activity, you will be logged out in 1 minute`, {
      toastId: "prompt/active",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    logout()
    toast.error(`You have been logged out due to inactivity`, {
      toastId: "prompt/active",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 15,
    promptBeforeIdle: 1000 * 60 * 1,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 500,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <DotnetClientProvider>
          <LandingContextProvider>
            <FluentProvider theme={pwcLightTheme}>
              <div className="ux-root">
                <Routes>
                  <Route path="/landing" Component={LandingControl} />
                  <Route path="/subscriptions/*" Component={SubscriptionControl} />
                  <Route path="/workspaces/*" Component={WorkspaceControl} />
                  <Route path="/applications" Component={ApplicationControl} />
                  <Route path="/images" Component={ImageControl} />
                  <Route path="*" Component={Root} />
                </Routes>
              </div>
              <ToastContainer />
            </FluentProvider>
          </LandingContextProvider>
        </DotnetClientProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}