import "./DataAssetSummary.scss"
import { Icon, IconButton } from "@arq-apps/ui"
import { ENDPOINTS } from "@arq-apps/util"
import { useContext } from "src/hooks"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { MessagesStorageStorageFileType, MessagesDataAssetsTDataAssetActivity } from "@arq-apps/dotnet"
import { toast } from "react-toastify"
import DOMPurify from "isomorphic-dompurify";
interface TemplateSummaryProps extends MessagesDataAssetsTDataAssetActivity {
	selected: boolean;
	onClick: (entityId: number, templateId: number, entityType: string) => void;
	appId: number | null;
}

export const DataAssetSummary = (props: TemplateSummaryProps) => {
	const { projectId } = useContext();
  	const { dmpClient } = useDotnetClient();

	const classes = [
		"ux-template-summary",
		props.selected && "selected"
	].filter(Boolean).join(" ");

	const onDownloadClick = async () => {
    	// TODO - error check appId and projectid
		const fileType: MessagesStorageStorageFileType = 4;

		try {
			const response = await fetch(`${ENDPOINTS.LANDING}/storage/download_file/${props.url},${props.appId},${projectId},${fileType}`, {
				headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }
			})
			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.setAttribute('href', url);
				a.setAttribute('download', props.file_name ?? "filename.txt");
				document.body.appendChild(a);
				a.click();
				a.remove();
				}
			else {
				let errMessage = 'Unknown error';
				if (response.statusText)
					errMessage = response.statusText;
				try {
					const tryJson = await response.json();
					if (tryJson.Message)
						errMessage = tryJson.Message;
				} catch (error) {
					// ignore
				}
				toast.error('Error downloading file: ' + DOMPurify.sanitize(errMessage));
			}
		} catch (error) {
			toast.error(`Error downloading file: ' + ${DOMPurify.sanitize(`${error}`)}`);
			console.error(DOMPurify.sanitize(`${error}`))
		}
	}

	return (
		<div className={classes} onClick={() => props.onClick(props.entity.id, props.data_asset.id, props.entity.entity_type.name)}>
			<div className="justify-between">
				<div>
					<div className="label">
						{props.entity.name}
					</div>
					<div className="ftname">
						{props.data_asset.name}
					</div>
					<div className="filename ellipsis">
						{props.file_name}
					</div>
				</div>
				<div className="action-container">
					{props.state === "Uploaded" && <Icon icon="search-outline" color="#FFBF1F" />}
					{props.state === "Final" && <Icon icon="circle-checkmark-outline" color="#22922E" />}
					{props.state === "None" && <Icon icon="time-outline" color="#E8E8E8" />}

					{props.url && <IconButton icon="download-outline" onClick={onDownloadClick} />}
				</div>
			</div>
		</div>
	)
}

export default DataAssetSummary