/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesSupportImageItem } from '../models/MessagesSupportImageItem';
import type { SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues } from '../models/SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupportApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * flush the redis cache (Authorised Roles: SuperUser)
     * @returns boolean OK
     * @throws ApiError
     */
    public getSupportApiFlushRedis(): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/support/flush-redis',
        });
    }

    /**
     * set an arbitrary redis key/value (Authorised Roles: SuperUser)
     * @param key
     * @param value
     * @returns boolean OK
     * @throws ApiError
     */
    public getSupportApiSetRedis(
        key?: string,
        value?: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/support/set-redis',
            query: {
                'key': key,
                'value': value,
            },
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @returns MessagesSupportImageItem OK
     * @throws ApiError
     */
    public getSupportApiListImages(): CancelablePromise<Array<MessagesSupportImageItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/support/listImages',
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param formData
     * @returns MessagesSupportImageItem OK
     * @throws ApiError
     */
    public postSupportApiAddImage(
        formData?: {
            formData?: Array<SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues>;
        },
    ): CancelablePromise<Array<MessagesSupportImageItem>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/support/addImage',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param imageKey
     * @returns binary OK
     * @throws ApiError
     */
    public getSupportApiGetImage(
        imageKey?: string,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/support/getImage',
            query: {
                'imageKey': imageKey,
            },
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param imageKey
     * @returns MessagesSupportImageItem OK
     * @throws ApiError
     */
    public postSupportApiDeleteImage(
        imageKey?: string,
    ): CancelablePromise<Array<MessagesSupportImageItem>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/support/deleteImage',
            query: {
                'imageKey': imageKey,
            },
        });
    }

}
