import "./WorkspaceControl.scss";
import { Logo, UserWidget } from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useMemo, useState } from "react";
import {
  TabList,
  Tab,
  SelectTabEvent,
  SelectTabData,
} from "@fluentui/react-components";
import { Building24Regular, Document24Regular } from "@fluentui/react-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WorkspaceDetailsPanel } from "./WorkspaceDetailsPanel";
import { WorkspaceEntitiesPanel } from "./WorkspaceEntitiesPanel";
import { WorkspaceFilesPanel } from "./WorkspaceFilesPanel"

export const WorkspaceControl = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userRole: number = parseInt(sessionStorage.getItem("roles") || "1");
  const navigate = useNavigate();
  
  const subscriptionId = useMemo(() => {
    const subId =  searchParams.get("subscriptionId");
    if (subId === null) navigate('/');
    return subId;
  }, [searchParams]);

  const tab = useMemo(() => {
    return searchParams.get("tab");
  }, [searchParams]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    searchParams.set("tab", data.value as string);
    setSearchParams(searchParams);
  };

  return (
    <div className="workspaces">
      <div className="header">
        <Logo breadcrumbs />
        <UserWidget />
      </div>
      <div className="container">
        <TabList selectedValue={tab} onTabSelect={onTabSelect}>
          <Tab id="details" value="details">
            Details
          </Tab>
          <Tab id="entities" value="entities" icon={<Building24Regular />}>
            Entities
          </Tab>
          <Tab id="files" value="files" icon={<Document24Regular />}>
            Files
          </Tab>
        </TabList>
        <div style={{ padding: "20px 10px" }}>
          {tab === "details" && <WorkspaceDetailsPanel />}
          {tab === "entities" && <WorkspaceEntitiesPanel />}
          {tab === "files" && <WorkspaceFilesPanel />}
        </div>
      </div>
      <Footer />
    </div>
  );
};