import "./Breadcrumbs.scss"
import { useLocation } from "react-router"
import { useSearchParams } from "react-router-dom"

interface BreadcrumbsProps {

}

{/* "Data Modelling Platform / OurCorp | Sibanye | Strategic Workforce Designer Tool */}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const orgName = searchParams.get("orgName");
  const projectId = searchParams.get("projectId");
  const projectName = searchParams.get("projectName");
  const appId = searchParams.get("appId");
  const appName = searchParams.get("appName");
  const tab = searchParams.get("tab");
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <div className="breadcrumbs">
      {pathname && (
        <div onClick={() => setSearchParams({})}>
          <div className="breadcrumb">{capitalize(pathname.slice(1))}</div>
        </div>
      )}
      {orgName && (
        <div className="breadcrumb">
          <div className="breadcrumb">{":" + "  "}</div>
          <div className="breadcrumb">{orgName}</div>
        </div>
      )}
      {projectName && (
        <div className="breadcrumb">
          <div className="breadcrumb">{" | "}</div>
          <div className="breadcrumb">{projectName}</div>
        </div>
      )}
      {appName && (
        <div className="breadcrumb">
          <div className="breadcrumb">{projectName !== null ? ' | ' : '/'}</div>
          <div className="breadcrumb">{appName}</div>
        </div>
      )}
    </div>
  )
}