import { MessagesSubscriptionsOrganisationWorkspace } from "@arq-apps/dotnet"
import { Button, createTableColumn, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components"
import { Desktop24Regular, Navigation24Regular } from "@fluentui/react-icons"
import { useEffect, useState } from "react"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AddWorkspaceForm } from "../Forms/AddWorkspaceForm"

export const OrganisationWorkspacesPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [organisationWorkspaces, setOrganisationWorkspaces] = useState<MessagesSubscriptionsOrganisationWorkspace[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<MessagesSubscriptionsOrganisationWorkspace>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);

  const orgId = parseInt(searchParams.get("orgId") ?? "");
  const orgName = searchParams.get("orgName")

  const fetchWorkspacesList = async () => {
    dmpClient?.subscriptionsApi
    .getSubscriptionsApiGetOrganisationWorkspaces(orgId)
      .then((result) => {
        setOrganisationWorkspaces(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchWorkspacesList()
  }, [isDMPClientLoaded])

  const columns: TableColumnDefinition<MessagesSubscriptionsOrganisationWorkspace>[] = [
    createTableColumn<MessagesSubscriptionsOrganisationWorkspace>({
      columnId: "projectName",
      compare: (a, b) => {
        return a.projectName.localeCompare(b.projectName);
      },
      renderHeaderCell: () => {
        return "Project";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            media={<Desktop24Regular color={!item.isFrozen ? "#D04A02" : "#D1D1D1"} />}
            onClick={() => navigate(`/workspaces?orgId=${orgId}&orgName=${orgName}&subscriptionId=${item.subscriptionId}&tab=details&appName=${item.applicationName}&projectName=${item.projectName}`)}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.projectName}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>
                {item.applicationName}
              </span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationWorkspace>({
      columnId: "teamCount",
      compare: (a, b) => {
        return a.teamCount - b.teamCount;
      },
      renderHeaderCell: () => {
        return "Teams";
      },
      renderCell: (item) => {
        return item.teamCount.toString()
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationWorkspace>({
      columnId: "directLinkedCount",
      compare: (a, b) => {
        return a.directLinkedCount - b.directLinkedCount;
      },
      renderHeaderCell: () => {
        return "Direct links";
      },
      renderCell: (item) => {
        return item.directLinkedCount.toString()
      },
    }),
    // createTableColumn<MessagesSubscriptionsOrganisationWorkspace>({
    //   columnId: "actions",
    //   renderHeaderCell: () => {
    //     return "Actions";
    //   },
    //   renderCell: (item) => {
    //     return (
    //       <Menu>
    //         <MenuTrigger disableButtonEnhancement>
    //           <Button icon={<Navigation24Regular/>}/>
    //         </MenuTrigger>

    //         <MenuPopover>
    //           <MenuList>
    //              <MenuItem
    //               onClick={() => {
    //                 setSelectedWorkspace(item);
    //                 setIsEditOpen(true);
    //               }}
    //             >
    //               Edit workspace
    //             </MenuItem>
    //           </MenuList>
    //         </MenuPopover>
    //       </Menu>
    //     );
    //   },
    // })
  ];

  return (
    <>
      <DataGridComponent 
        items={organisationWorkspaces}
        columns={columns}
        heightAdjustment={90}
        onAdd={() => setIsAddOpen(true)}
      />
      {/* <OverlayDrawerComponent
        open={isEditOpen}
        setOpen={setIsEditOpen}
        title={"Edit workspace"}
        content={
          <AddUserToOrgForm
            onSubmit={() => setIsEditOpen(false)}
          />
        }
      /> */}
      <OverlayDrawerComponent
        open={isAddOpen}
        size="medium"
        setOpen={setIsAddOpen}
        title={"Add workspace"}
        content={
          <AddWorkspaceForm
            onSubmit={() => setIsAddOpen(false)}
          />
        }
      />
    </>
  )
}