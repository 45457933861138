import { MessagesApplicationsApplicationRole } from "@arq-apps/dotnet";
import {
  Button,
  createTableColumn,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TableColumnDefinition,
} from "@fluentui/react-components";
import { Navigation24Regular } from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { DataGridComponent } from "../DataGrid/DataGridComponent";
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent";
import { AddApplicationRoleForm } from "../Forms/AddApplicationRoleForm";

interface AppRolesPanelProps {
  appId: number;
}

type LabelValueCell = {
  label: string;
  value: string;
};

type NumberValueCell = {
  label: number;
  value: number;
};

export type ApplicationRoleItem = {
  applicationId: number;
  id: number;

  name: LabelValueCell;
  description: LabelValueCell;

  memberCount: NumberValueCell;
  teamCount: NumberValueCell;
};

export const AppRolesPanel = (props: AppRolesPanelProps) => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [applicationRoles, setApplicationRoles] = useState<
    MessagesApplicationsApplicationRole[]
  >([]);
  const [addAppRoleDrawerOpen, setAddAppRoleDrawerOpen] =
    useState<boolean>(false);
  const [editAppRoleDrawerOpen, setEditAppRoleDrawerOpen] =
    useState<boolean>(false);
  const [selectedAppRole, setSelectedAppRole] = useState<ApplicationRoleItem>();

  const fetchRolesList = async () => {
    dmpClient?.applicationsApi
      .getApplicationsApiListApplicationRoles(props.appId)
      .then((result) => {
        setApplicationRoles(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    const userRole: number = parseInt(sessionStorage.getItem("roles") || "1");
    console.log(userRole);
    if (isDMPClientLoaded) fetchRolesList();
  }, [isDMPClientLoaded]);

  const items: ApplicationRoleItem[] = useMemo(() => {
    return applicationRoles.map((role) => {
      return {
        id: role.id,
        applicationId: role.applicationId,

        name: { label: role.name, value: role.name },
        description: { label: role.description, value: role.description },

        memberCount: { label: role.memberCount, value: role.memberCount },
        teamCount: { label: role.teamCount, value: role.teamCount },
      } as ApplicationRoleItem;
    });
  }, [applicationRoles]);

  const columns: TableColumnDefinition<ApplicationRoleItem>[] = [
    createTableColumn<ApplicationRoleItem>({
      columnId: "name",
      compare: (a, b) => {
        return a.name.value.localeCompare(b.name.value);
      },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item) => {
        return item.name.value;
      },
    }),
    createTableColumn<ApplicationRoleItem>({
      columnId: "description",
      compare: (a, b) => {
        return a.description.value.localeCompare(b.description.value);
      },
      renderHeaderCell: () => {
        return "Description";
      },
      renderCell: (item) => {
        return item.description.value;
      },
    }),
    createTableColumn<ApplicationRoleItem>({
      columnId: "memberCount",
      compare: (a, b) => {
        return a.memberCount.value - b.memberCount.value;
      },
      renderHeaderCell: () => {
        return "Member Count";
      },
      renderCell: (item) => {
        return item.memberCount.value;
      },
    }),
    createTableColumn<ApplicationRoleItem>({
      columnId: "teamCount",
      compare: (a, b) => {
        return a.teamCount.value - b.teamCount.value;
      },
      renderHeaderCell: () => {
        return "Team Count";
      },
      renderCell: (item) => {
        return item.teamCount.value;
      },
    }),
    createTableColumn<ApplicationRoleItem>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular />} />
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setEditAppRoleDrawerOpen(true);
                    setSelectedAppRole(item);
                  }}
                >
                  Edit role
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    }),
  ];

  return (
    <div className="container">
      <DataGridComponent
        items={items}
        columns={columns}
        heightAdjustment={140}
        onAdd={() => setAddAppRoleDrawerOpen(true)}
      />
      <OverlayDrawerComponent
        key="addform"
        open={addAppRoleDrawerOpen}
        setOpen={setAddAppRoleDrawerOpen}
        title="Add application role"
        content={
          <AddApplicationRoleForm
            appId={props.appId}
            onSubmit={() => {
              setAddAppRoleDrawerOpen(false);
              fetchRolesList();
            }}
          />
        }
      />
      <OverlayDrawerComponent
        key="editform"
        open={editAppRoleDrawerOpen}
        setOpen={setEditAppRoleDrawerOpen}
        title="Edit application role"
        content={
          <AddApplicationRoleForm
            edit
            appId={props.appId}
            onSubmit={() => {
              setEditAppRoleDrawerOpen(false);
              fetchRolesList();
            }}
            selectedAppRole={selectedAppRole}
          />
        }
      />
    </div>
  );
};
