import { useMemo, useState } from "react"
import "./DataGridComponent.scss";
import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableColumnDefinition,
  SearchBox,
  Button,
  Badge
} from "@fluentui/react-components";
import { ImageItem } from "../ImageControl/ImageControl";
import { useFooterHeight } from "src/hooks";
import { MessagesApplicationsApplication, MessagesApplicationsApplicationTeam, MessagesStorageStorageDetailItem, MessagesSubscriptionsOrganisationProject, MessagesSubscriptionsOrganisationSummary, MessagesSubscriptionsOrganisationTeam, MessagesSubscriptionsOrganisationUser, MessagesSubscriptionsOrganisationWorkspace, MessagesWorkspacesEntity } from "@arq-apps/dotnet";
import { ApplicationRoleItem } from "../ApplicationControl/AppRolesPanel";

type DataGridType =
  | MessagesSubscriptionsOrganisationSummary
  | ImageItem
  | MessagesApplicationsApplication
  | ApplicationRoleItem
  | MessagesSubscriptionsOrganisationUser
  | MessagesSubscriptionsOrganisationProject
  | MessagesSubscriptionsOrganisationTeam
  | MessagesSubscriptionsOrganisationWorkspace
  | MessagesWorkspacesEntity
  | MessagesApplicationsApplicationTeam
  | MessagesStorageStorageDetailItem

type DataGridColumnDefinitions = 
  | TableColumnDefinition<MessagesSubscriptionsOrganisationSummary>[]
  | TableColumnDefinition<ImageItem>[] 
  | TableColumnDefinition<MessagesApplicationsApplication>[]
  | TableColumnDefinition<MessagesSubscriptionsOrganisationUser>[]
  | TableColumnDefinition<ApplicationRoleItem>[]
  | TableColumnDefinition<MessagesSubscriptionsOrganisationProject>[]
  | TableColumnDefinition<MessagesSubscriptionsOrganisationTeam>[]
  | TableColumnDefinition<MessagesSubscriptionsOrganisationWorkspace>[]
  | TableColumnDefinition<MessagesWorkspacesEntity>[]
  | TableColumnDefinition<MessagesApplicationsApplicationTeam>[]
  | TableColumnDefinition<MessagesStorageStorageDetailItem>[]

interface DataGridProps {
  items: any[]
  columns: DataGridColumnDefinitions
  onAdd?: () => void
  heightAdjustment?: number
}

export const DataGridComponent = (props: DataGridProps) => {
  const [search, setSearch] = useState<string>("");
  const footerHeight = useFooterHeight();

  const filteredItems = useMemo(() => {
    return props.items.filter(item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase()))
  }, [search, props.items])

  const getRowId = (item: DataGridType) => {
    if (item.hasOwnProperty("id")) return (item as MessagesSubscriptionsOrganisationSummary | MessagesSubscriptionsOrganisationUser | ApplicationRoleItem | MessagesSubscriptionsOrganisationTeam | MessagesApplicationsApplicationTeam).id
    if (item.hasOwnProperty("subscriptionId")) return (item as MessagesSubscriptionsOrganisationWorkspace).subscriptionId
    if (item.hasOwnProperty("filename")) return (item as ImageItem).filename.label
    if (item.hasOwnProperty("applicationId")) return (item as MessagesApplicationsApplication).applicationId
    return "no row id"
  }

  const onAddButtonClick = () => {
    if (props.onAdd !== undefined) {
      props.onAdd()
    }
  }

  return (
    <div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "20px"}}>
        <SearchBox value={search} onChange={(e, data) => setSearch(data.value)} />
      </div>

      <div style={{backgroundColor: "white", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px"}}>
        <div><Badge appearance="outline">{filteredItems.length} items</Badge></div>
        {(props.onAdd !== undefined) && <Button onClick={() => onAddButtonClick() }>Add item</Button>}
      </div>
      
      <DataGrid
        items={filteredItems}
        columns={props.columns}
        sortable
        getRowId={getRowId}
        style={{backgroundColor: "white"}}
      >
        <DataGridHeader>
          <DataGridRow
          >
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell style={{fontWeight: 'bold'}}>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<DataGridType> style={{maxHeight: `calc(100vh - ${footerHeight}px - 250px - ${props.heightAdjustment ?? 0}px)`, overflowY: "auto"}}>
          {({ item, rowId }) => (
            <DataGridRow<DataGridType> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  )
}

export default DataGrid;