/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesSubscriptionsNewOrganisation } from '../models/MessagesSubscriptionsNewOrganisation';
import type { MessagesSubscriptionsNewOrganisationTeam } from '../models/MessagesSubscriptionsNewOrganisationTeam';
import type { MessagesSubscriptionsNewOrganisationUser } from '../models/MessagesSubscriptionsNewOrganisationUser';
import type { MessagesSubscriptionsNewOrganisationWorkspace } from '../models/MessagesSubscriptionsNewOrganisationWorkspace';
import type { MessagesSubscriptionsOrganisation } from '../models/MessagesSubscriptionsOrganisation';
import type { MessagesSubscriptionsOrganisationProject } from '../models/MessagesSubscriptionsOrganisationProject';
import type { MessagesSubscriptionsOrganisationSummary } from '../models/MessagesSubscriptionsOrganisationSummary';
import type { MessagesSubscriptionsOrganisationTeam } from '../models/MessagesSubscriptionsOrganisationTeam';
import type { MessagesSubscriptionsOrganisationTeamUser } from '../models/MessagesSubscriptionsOrganisationTeamUser';
import type { MessagesSubscriptionsOrganisationUser } from '../models/MessagesSubscriptionsOrganisationUser';
import type { MessagesSubscriptionsOrganisationWorkspace } from '../models/MessagesSubscriptionsOrganisationWorkspace';
import type { MessagesSubscriptionsProject } from '../models/MessagesSubscriptionsProject';
import type { MessagesSubscriptionsUpdateOrganisationTeam } from '../models/MessagesSubscriptionsUpdateOrganisationTeam';
import type { MessagesSubscriptionsUpdateOrganisationUser } from '../models/MessagesSubscriptionsUpdateOrganisationUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionsApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisation(
        orgId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationSummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     * Adds a new organisation. (Authorised Roles: SuperUser)
     * @param requestBody The organisation to add.
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddOrganisation(
        requestBody?: MessagesSubscriptionsNewOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates an existing organisation. (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody The organisation with updated information.
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateOrganisation(
        requestBody?: MessagesSubscriptionsOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Retrieves the list of organisations.
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisations(): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations',
        });
    }

    /**
     * Freezes an organisation, making it read-only. (Authorised Roles: ProjectAdministrator, PlatformAdministrator, SuperUser)
     * @param requestBody The organisation to freeze.
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeOrganisation(
        requestBody?: MessagesSubscriptionsOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/freeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Return user (Authorised Roles: ProjectAdministrator, PlatformAdministrator, SuperUser)
     * @param userId The ID of the user.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationUser(
        userId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation/user',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * Lists all users in an organisation. (Authorised Roles: ProjectAdministrator, PlatformAdministrator, SuperUser)
     * @param orgId The ID of the organisation.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public getSubscriptionsApiListOrganisationUsers(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation/users',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     * Adds a new user to an organisation. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody The new organisation user to add.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddUser(
        requestBody?: MessagesSubscriptionsNewOrganisationUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates an existing user within an organisation. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody The user with updated information.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateUser(
        requestBody?: MessagesSubscriptionsUpdateOrganisationUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Deletes a user from an organisation. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param userId The ID of the user to delete.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteUser(
        userId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/users',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param organisationId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddPlatformAdmin(
        organisationId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisations/admins',
            query: {
                'organisationId': organisationId,
                'adminId': adminId,
            },
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param organisationId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiRemovePlatformAdmin(
        organisationId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/admins',
            query: {
                'organisationId': organisationId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Lists all projects in an organisation. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId The ID of the organisation.
     * @returns MessagesSubscriptionsOrganisationProject OK
     * @throws ApiError
     */
    public getSubscriptionsApiListOrganisationProjects(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationProject>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation/projects',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     * Adds a new project to an organisation. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody The project to add.
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates an existing project within an organisation. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody The project with updated information.
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Freezes a project, making it read-only. (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody The project to freeze.
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects/freeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Unfreezes a project, making it editable again. (Authorised Roles: SuperUser)
     * @param requestBody The project to unfreeze.
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiUnfreezeProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects/unfreeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Adds a new administrator to a project. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param projectId The ID of the project.
     * @param adminId The ID of the user to add as an administrator.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProjectAdmin(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects/admins',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Removes an administrator from a project. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param projectId The ID of the project.
     * @param adminId The ID of the administrator to remove.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiRemoveProjectAdmin(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/projects/admins',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Adds a new leader to a project. (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param projectId The ID of the project.
     * @param adminId The ID of the user to add as a leader.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProjectLeader(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects/leaders',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Removes a leader from a project. (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param projectId The ID of the project.
     * @param adminId The ID of the leader to remove.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiRemoveProjectLeader(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/projects/leaders',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Freezes a user, making their account read-only. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param userId The ID of the user to freeze.
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeUser(
        userId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/users/freeze',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * Retrieves the list of organisation project teams. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId The ID of the organisation.
     * @param appId The ID of the application.
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationTeams(
        orgId: number,
        appId: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/teams',
            query: {
                'orgId': orgId,
                'appId': appId,
            },
        });
    }

    /**
     * Adds a new project team to the organisation. (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody The team to add.
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddTeam(
        requestBody?: MessagesSubscriptionsNewOrganisationTeam,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates a project team (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateTeam(
        requestBody?: MessagesSubscriptionsUpdateOrganisationTeam,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Freezes a team (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param teamId
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeTeam(
        teamId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/teams/freeze',
            query: {
                'teamId': teamId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeamUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddTeamUser(
        requestBody?: MessagesSubscriptionsOrganisationTeamUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeamUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/teams/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeamUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteTeamUser(
        requestBody?: MessagesSubscriptionsOrganisationTeamUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeamUser>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/teams/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationWorkspaces(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationWorkspace>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/workspaces',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddOrganisationWorkspaces(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspace,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisations/workspaces',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteOrganisationWorkspaces(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspace,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @param workspaceId
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationWorkspace(
        orgId?: number,
        workspaceId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/workspace',
            query: {
                'orgId': orgId,
                'workspaceId': workspaceId,
            },
        });
    }

}
