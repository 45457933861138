import "./WorkspaceDetailsPanel.scss";
import { Avatar } from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useState } from "react";
import {
  MessagesApplicationsApplicationTeam,
  MessagesSubscriptionsOrganisationTeam,
  MessagesSubscriptionsOrganisationWorkspace,
} from "@arq-apps/dotnet";
import { useSearchParams } from "react-router-dom";
import { AddWorkspaceForm } from "../Forms/AddWorkspaceForm"
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent"

export const WorkspaceDetailsPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [workspace, setWorkspace] = useState<MessagesSubscriptionsOrganisationWorkspace>();
  const [projectTeams, setProjectTeams] = useState<MessagesSubscriptionsOrganisationTeam[]>();
  const [supportTeams, setSupportTeams] = useState<MessagesApplicationsApplicationTeam[]>();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const orgId = parseInt(searchParams.get("orgId") ?? "");
  const orgName = searchParams.get("orgName");
  const workspaceId = parseInt(searchParams.get("subscriptionId") ?? "");

  const fetchWorkspace = async () => {
    const result =
      await dmpClient?.subscriptionsApi.getSubscriptionsApiGetOrganisationWorkspace(
        orgId,
        workspaceId
      );
    setWorkspace(result);
  };

  const fetchProjectTeams = async () => {
    const _projectTeams =
      await dmpClient?.workspacesApi.getWorkspacesApiGetProjectTeamsWorkspace(
        workspaceId
      );
    setProjectTeams(_projectTeams);
  };

  const fetchSupportTeams = async () => {
    const _supportTeams =
      await dmpClient?.workspacesApi.getWorkspacesApiGetSupportTeamsWorkspace(
        workspaceId
      );
    setSupportTeams(_supportTeams);
  };

  useEffect(() => {
    if (isDMPClientLoaded) {
      fetchWorkspace();
      fetchProjectTeams();
      fetchSupportTeams();
    }
  }, [isDMPClientLoaded]);

  return (
    <div className="organisation-card">
      <Avatar name={workspace?.applicationName} size={64} color={"colorful"} />
      <div className="organisation-content">
        <div className="title">Organisation</div>
        <div className="subtitle">{orgName}</div>

        <div className="title">Project</div>
        <div className="subtitle">{workspace?.projectName}</div>

        <div className="title">Application</div>
        <div className="subtitle">{workspace?.applicationName}</div>

        <div className="title">Project teams</div>
        <div className="pill-container">
          {projectTeams?.length === 0 && "No project teams"}
          {projectTeams?.map((pt) => (
            <div className="pill" key={pt.id}>
              {pt.name}
            </div>
          ))}
        </div>
        <div className="title">Support teams</div>
        <div className="pill-container">
          {supportTeams?.length === 0 && "No support teams"}
          {supportTeams?.map((st) => (
            <div className="pill" key={st.id}>
              {st.name}
            </div>
          ))}
        </div>
      </div>
      <Edit24Regular onClick={() => setIsEditOpen(true)} />
      <OverlayDrawerComponent
        open={isEditOpen}
        setOpen={setIsEditOpen}
        title="Edit workspace"
        size="medium"
        content={<AddWorkspaceForm
          onSubmit={() => setIsEditOpen(false)} 
          edit
          selectedWorkspace={workspace}
          selectedProjectTeams={projectTeams}
          selectedSupportTeams={supportTeams}
        />}
      />
    </div>
  );
};
