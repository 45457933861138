import "./Form.scss";
import { useForm } from "react-hook-form";
import { Field, Input, Switch } from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { MessagesSubscriptionsNewOrganisationUser, MessagesSubscriptionsOrganisationUser } from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom"

export type UserForm = {
  email: string;
  givenName: string;
  familyName: string;
  projectMember?: boolean;
  projectAdmin?: boolean;
  projectLeader?: boolean;
  platformAdmin?: boolean;
  superUser?: boolean;
  applicationAdmin?: boolean;
};

interface AddUserToOrgFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedUser?: MessagesSubscriptionsOrganisationUser;
}

export const AddUserToOrgForm = (props: AddUserToOrgFormProps) => {
  const { dmpClient } = useDotnetClient();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { 
    register,
    formState: { errors  },
    setError,
    reset,
    handleSubmit
  } = useForm<UserForm>({
    defaultValues: props.selectedUser ? {
      email: props.selectedUser.email,
      givenName: props.selectedUser.givenName,
      familyName: props.selectedUser.familyName,
      projectMember: ((props.selectedUser.roles & 1) > 0),
      projectAdmin: ((props.selectedUser.roles & 2) > 0),
      projectLeader: ((props.selectedUser.roles & 4) > 0),
      platformAdmin: ((props.selectedUser.roles & 8) > 0),
      superUser: ((props.selectedUser.roles & 16) > 0),
      applicationAdmin: ((props.selectedUser.roles & 32) > 0)
    } : {
      email: "",
      givenName: "",
      familyName: "",
    },
  });

  const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");
  const orgId = parseInt(searchParams.get("orgId") ?? "");

  const onSubmit = (data: UserForm) => {
    if (!orgId) {
      toast.error("No organisation id")
      return
    }
    const projectMember = data.projectMember ? 1 : 0
    const projectAdmin = data.projectAdmin ? 2 : 0
    const projectLeader = data.projectLeader ? 4 : 0
    const platformAdmin = data.platformAdmin ? 8 : 0
    const superUser = data.superUser ? 16 : 0
    const applicationAdmin = data.applicationAdmin ? 32 : 0
    const roles = [projectMember, projectAdmin, projectLeader, platformAdmin, superUser, applicationAdmin].reduce((a,b)=>a+b)

    const user: MessagesSubscriptionsNewOrganisationUser = {
      organisationId: orgId,
      email: data.email,
      givenName: data.givenName,
      familyName: data.familyName,
      roles: roles
    }

    dmpClient?.subscriptionsApi.putSubscriptionsApiAddUser(user)
    .then((result) => {
      props.onSubmit()
      toast.success("Item succesfully added")
      reset()
    })
    .catch((err) => {
      props.onSubmit()
      toast.error("Error adding item")
      console.warn(err);
    })
    .finally(() => {});
  }

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Email"
          validationState={errors.email ? "error" : "none"}
          validationMessage={errors.email?.message}
          >
          <Input {...register("email", {required: "Email is required", maxLength: 100})} />
        </Field>
        <Field
          label="Given name"
          validationState={errors.givenName ? "error" : "none"}
          validationMessage={errors.givenName?.message}
          >
          <Input {...register("givenName", {required: "Given name is required", maxLength: 100})} />
        </Field>
        <Field
          label="Family name"
          validationState={errors.familyName ? "error" : "none"}
          validationMessage={errors.familyName?.message}
          >
          <Input {...register("familyName", {required: "Family name is required", maxLength: 100})} />
        </Field>
        {((userRole & 2) > 0 || (userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) && <Field
          label="Project member"
          validationState={errors.projectMember ? "error" : "none"}
          validationMessage={errors.projectMember?.message}
          >
          <Switch {...register("projectMember")}/>
        </Field>}
        {((userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) && <Field
          label="Project admin"
          validationState={errors.projectAdmin ? "error" : "none"}
          validationMessage={errors.projectAdmin?.message}
          >
          <Switch {...register("projectAdmin")}/>
        </Field>}
        {((userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) && <Field
          label="Project leader"
          validationState={errors.projectLeader ? "error" : "none"}
          validationMessage={errors.projectLeader?.message}
          >
          <Switch {...register("projectLeader")}/>
        </Field>}
        {((userRole & 8) > 0 || (userRole & 16) > 0) && <Field
          label="Platform admin"
          validationState={errors.platformAdmin ? "error" : "none"}
          validationMessage={errors.platformAdmin?.message}
          >
          <Switch {...register("platformAdmin")}/>
        </Field>}
        {((userRole & 16) > 0) && <Field
          label="Super user"
          validationState={errors.superUser ? "error" : "none"}
          validationMessage={errors.superUser?.message}
          >
          <Switch {...register("superUser")}/>
        </Field>}
        {((userRole & 16) > 0) && <Field
          label="Application admin"
          validationState={errors.applicationAdmin ? "error" : "none"}
          validationMessage={errors.applicationAdmin?.message}
          >
          <Switch {...register("applicationAdmin")}/>
        </Field>}
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  )
}