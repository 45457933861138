import "./AppDetailsPanel.scss";
import { Avatar } from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent";
import { useEffect, useState } from "react";
import {
  MessagesApplicationsApplication,
  MessagesApplicationsApplicationAttributes,
  MessagesApplicationsPictogram,
  MessagesApplicationsUser,
  MessagesSubscriptionsOrganisationUser,
} from "@arq-apps/dotnet";
import { useSearchParams } from "react-router-dom";
import { AddApplicationForm } from "../Forms/AddApplicationForm";
import { EditApplicationAttribsForm } from "../Forms/ApplicationAttribsForm";
import { ENDPOINTS } from "@arq-apps/util";
import { ErrorBoundary } from "../ErrorBoundary";

export const AppDetailsPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [application, setApplication] =
    useState<MessagesApplicationsApplication>();
  const [applicationAtt, setApplicationAtt] =
    useState<MessagesApplicationsApplicationAttributes>();
  const [editAppOpen, setEditAppOpen] = useState(false);
  const [editAppAttrOpen, setEditAppAttrOpen] = useState(false);
  const [pictograms, setPictograms] = useState<MessagesApplicationsPictogram[]>(
    []
  );
  const [users, setUsers] = useState<MessagesApplicationsUser[]>([]);
  const [keyContact1, setKeyContact1] =
    useState<MessagesSubscriptionsOrganisationUser>();
  const [keyContact2, setKeyContact2] =
    useState<MessagesSubscriptionsOrganisationUser>();
  const [keyContact3, setKeyContact3] =
    useState<MessagesSubscriptionsOrganisationUser>();

  const appId = parseInt(searchParams.get("appId") ?? "");
  const imagePrefix = `${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=`;

  const fetchApplication = async () => {
    const result =
      await dmpClient?.applicationsApi.getApplicationsApiGetApplications();
    const app = result?.find((x) => x.applicationId == appId);
    setApplication(app);

    dmpClient?.applicationsApi
      .getApplicationsApiGetApplicationAttributes(appId)
      .then((res) => {
        setApplicationAtt(res);

        if (!res.keyContactId1) setKeyContact1(undefined);
        else if (keyContact1?.id !== res.keyContactId1)
          dmpClient?.subscriptionsApi
            .getSubscriptionsApiGetOrganisationUser(res.keyContactId1)
            .then((kc) => {
              setKeyContact1(kc);
            });

        if (!res.keyContactId2) setKeyContact2(undefined);
        else if (res.keyContactId2 && keyContact2?.id !== res.keyContactId2)
          dmpClient?.subscriptionsApi
            .getSubscriptionsApiGetOrganisationUser(res.keyContactId2)
            .then((kc) => {
              setKeyContact2(kc);
            });

        if (!res.keyContactId3) setKeyContact3(undefined);
        else if (res.keyContactId3 && keyContact3?.id !== res.keyContactId3)
          dmpClient?.subscriptionsApi
            .getSubscriptionsApiGetOrganisationUser(res.keyContactId3)
            .then((kc) => {
              setKeyContact3(kc);
            });
      });
  };

  const fetchPictograms = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetPictograms()
      .then((result) => {
        setPictograms(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchUsers = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetUsers()
      .then((result) => {
        setUsers(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchApplication();
  }, [isDMPClientLoaded]);

  return (
    <div className="application-cards">
      <ErrorBoundary>
        <div className="application-card">
          <Avatar name={application?.appKey} size={64} color={"colorful"} />
          <div className="application-content">
            <div className="heading">{application?.appName}</div>
            <div className="description">{application?.description}</div>
            <div className="pill-container">
              <div className="pill active">{application?.status}</div>
              <div className="pill">
                {application?.fileTypes} up to {application?.maxFileSizeMb}Mb
              </div>
            </div>
          </div>
          {applicationAtt?.imageKey && (
            <div
              className="key-image"
              style={{
                backgroundImage: `url("${imagePrefix}${applicationAtt?.imageKey}")`,
              }}
            />
          )}
          <Edit24Regular onClick={() => setEditAppOpen(true)} />
          <OverlayDrawerComponent
            open={editAppOpen}
            setOpen={setEditAppOpen}
            title="Edit application details"
            content={
              <AddApplicationForm
                onSubmit={() => {
                  setEditAppOpen(false);
                  fetchApplication();
                }}
                selectedApp={application!}
                edit
              />
            }
          />
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        <div className="application-card">
          <div className="application-content">
            <div style={{ fontSize: "16px", fontWeight: "800" }}>
              {applicationAtt?.problemStatement}
            </div>
            <div style={{ fontSize: "16px" }}>
              {applicationAtt?.keyQuestions}
            </div>
            <div style={{ fontSize: "16px", fontWeight: "800" }}>
              How this app tackles the problem statement
            </div>
            <hr />
            <div style={{ fontSize: "16px" }}>
              {applicationAtt?.solutionDescription}
            </div>

            <br />
            <div style={{ fontSize: "16px", fontWeight: "800" }}>
              Benefits that differentiate this app
            </div>
            <hr />
            <div className="benefits">
              <div className="benefit">
                {applicationAtt?.differentiatedBenefit1Pictogram && (
                  <div
                    className="benefit-image"
                    style={{
                      backgroundImage: `url("${imagePrefix}${applicationAtt?.differentiatedBenefit1Pictogram}")`,
                    }}
                  />
                )}
                <div className="benefit-description">
                  {applicationAtt?.differentiatedBenefit1}
                </div>
              </div>
              <div className="benefit">
                {applicationAtt?.differentiatedBenefit2Pictogram && (
                  <div
                    className="benefit-image"
                    style={{
                      backgroundImage: `url("${imagePrefix}${applicationAtt?.differentiatedBenefit2Pictogram}")`,
                    }}
                  />
                )}
                <div className="benefit-description">
                  {applicationAtt?.differentiatedBenefit2}
                </div>
              </div>
              <div className="benefit">
                {applicationAtt?.differentiatedBenefit3Pictogram && (
                  <div
                    className="benefit-image"
                    style={{
                      backgroundImage: `url("${imagePrefix}${applicationAtt?.differentiatedBenefit3Pictogram}")`,
                    }}
                  />
                )}
                <div className="benefit-description">
                  {applicationAtt?.differentiatedBenefit3}
                </div>
              </div>
            </div>
            <br />
            <div style={{ fontSize: "16px", fontWeight: "800" }}>
              Get in touch with our app leads
            </div>
            <hr />
            <div className="keyContacts">
              {keyContact1 && (
                <div className="keyContact">
                  {keyContact1.profilePicture && (
                    <div
                      className="keyContact-image"
                      style={{
                        backgroundImage: `url("${imagePrefix}${keyContact1.profilePicture}")`,
                      }}
                    />
                  )}
                  <div className="keyContactName">
                    {keyContact1.givenName}
                    &nbsp;
                    {keyContact1.familyName}
                  </div>
                  <div className="keyContactEmail">{keyContact1.email}</div>
                </div>
              )}
              {keyContact2 && (
                <div className="keyContact">
                  {keyContact2.profilePicture && (
                    <div
                      className="keyContact-image"
                      style={{
                        backgroundImage: `url("${imagePrefix}${keyContact2.profilePicture}")`,
                      }}
                    />
                  )}
                  <div className="keyContactName">
                    {keyContact2.givenName}
                    &nbsp;
                    {keyContact2.familyName}
                  </div>
                  <div className="keyContactEmail">{keyContact2.email}</div>
                </div>
              )}
              {keyContact3 && (
                <div className="keyContact">
                  {keyContact3.profilePicture && (
                    <div
                      className="keyContact-image"
                      style={{
                        backgroundImage: `url("${imagePrefix}${keyContact3.profilePicture}")`,
                      }}
                    />
                  )}
                  <div className="keyContactName">
                    {keyContact3.givenName}
                    &nbsp;
                    {keyContact3.familyName}
                  </div>
                  <div className="keyContactEmail">{keyContact3.email}</div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Edit24Regular
              onClick={() => {
                if (pictograms.length === 0)
                  Promise.all([fetchPictograms(), fetchUsers()]).then(() => {
                    setEditAppAttrOpen(true);
                  });
                else setEditAppAttrOpen(true);
              }}
            />
          </div>
          <OverlayDrawerComponent
            open={editAppAttrOpen}
            setOpen={setEditAppAttrOpen}
            title="Edit application details"
            size="medium"
            content={
              <EditApplicationAttribsForm
                onSubmit={() => {
                  setEditAppAttrOpen(false);
                  fetchApplication();
                }}
                selectedApp={application!}
                applicationAttribs={applicationAtt!}
                pictograms={pictograms}
                users={users}
              />
            }
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};
