import "./Form.scss";
import { useForm } from "react-hook-form";
import { Field, Input, Select } from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesApplicationsApplication,
  MessagesSubscriptionsNewOrganisationTeam,
  MessagesSubscriptionsOrganisationTeam,
  MessagesSubscriptionsUpdateOrganisationTeam,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface OrganisationTeamFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedOrganisationTeam?: MessagesSubscriptionsOrganisationTeam;
}

type OrganisationTeamForm = MessagesSubscriptionsOrganisationTeam & {
  appId: number;
};

export const OrganisationTeamForm = (props: OrganisationTeamFormProps) => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [applications, setApplications] = useState<
    MessagesApplicationsApplication[]
  >([]);

  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit,
    // control
  } = useForm<OrganisationTeamForm>({
    defaultValues: props.edit
      ? {
          ...props.selectedOrganisationTeam,
          appId: props.selectedOrganisationTeam!.applicationId,
        }
      : {
          id: undefined,
          name: "",
          description: "",
          application: "",
          linkedProjectCount: 0,
          activeLinkedProjectCount: 0,
          linkedUserCount: 0,
          linkedRoleCount: 0,
        },
  });

  const fetchApplications = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetApplications()
      .then((result) => {
        setApplications(result);
        reset();
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) {
      fetchApplications();
    }
  }, [isDMPClientLoaded]);

  const onSubmit = (data: OrganisationTeamForm) => {
    if (props.edit) {
      const updatedTeam: MessagesSubscriptionsUpdateOrganisationTeam = {
        orgId: parseInt(searchParams.get("orgId") ?? ""),
        ...data,
      };
      dmpClient?.subscriptionsApi
        .postSubscriptionsApiUpdateTeam(updatedTeam)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("Item succesfully updated");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error updating item");
          console.warn(err);
        })
        .finally(() => {});
    } else {
      const newTeam: MessagesSubscriptionsNewOrganisationTeam = {
        orgId: parseInt(searchParams.get("orgId") ?? ""),
        ...data,
      };
      dmpClient?.subscriptionsApi
        .putSubscriptionsApiAddTeam(newTeam)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("Item succesfully added");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error adding item");
          console.warn(err);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Name"
          validationState={errors.name ? "error" : "none"}
          validationMessage={errors.name?.message}
        >
          <Input
            {...register("name", {
              required: "Name is required",
              maxLength: 100,
            })}
          />
        </Field>

        <Field
          label="Description"
          validationState={errors.description ? "error" : "none"}
          validationMessage={errors.description?.message}
        >
          <Input {...register("description", { maxLength: 200 })} />
        </Field>

        <Field
          required
          label="Application"
          validationState={errors.appId ? "error" : "none"}
          validationMessage={errors.appId?.message}
        >
          <Select
            disabled={props.edit}
            //placeholder="Application"
            {...register("appId", {
              required: "Application is required",
            })}
          >
            <option key={"none"} value={undefined}></option>
            {applications.map((a) => {
              return (
                <option key={a.applicationId} value={a.applicationId}>
                  {a.appName}
                </option>
              );
            })}
          </Select>
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
