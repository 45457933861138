import "./Form.scss";
import { useForm } from "react-hook-form";
import { Field, Input, Textarea, Select } from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesApplicationsApplication,
  MessagesApplicationsApplicationAttributes,
  MessagesApplicationsPictogram,
  MessagesApplicationsUser,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { ENDPOINTS } from "@arq-apps/util";

export type ApplicationAttribsForm = {
  problemStatement: string;
  keyQuestions: string | null;
  solutionDescription: string;
  differentiatedBenefit1: string | null;
  differentiatedBenefit1Pictogram: string | null;
  differentiatedBenefit2: string | null;
  differentiatedBenefit2Pictogram: string | null;
  differentiatedBenefit3: string | null;
  differentiatedBenefit3Pictogram: string | null;
  imageKey: string | null;

  keyContactId1: number | null;
  keyContactId2: number | null;
  keyContactId3: number | null;
};

interface ApplicationAttribsFormProps {
  onSubmit: () => void;
  selectedApp: MessagesApplicationsApplication;
  applicationAttribs: MessagesApplicationsApplicationAttributes;
  pictograms: MessagesApplicationsPictogram[];
  users: MessagesApplicationsUser[];
}

export const EditApplicationAttribsForm = (
  props: ApplicationAttribsFormProps
) => {
  const { dmpClient } = useDotnetClient();
  const {
    register,
    formState: { errors },
    setError,
    watch,
    reset,
    handleSubmit,
    // control
  } = useForm<ApplicationAttribsForm>({
    defaultValues: props.applicationAttribs,
  });

  const watchPict1 = watch("differentiatedBenefit1Pictogram");
  const watchPict2 = watch("differentiatedBenefit2Pictogram");
  const watchPict3 = watch("differentiatedBenefit3Pictogram");
  const watchImageKey = watch("imageKey");

  const onSubmit = (data: ApplicationAttribsForm) => {
    const updatedApp: MessagesApplicationsApplicationAttributes = {
      applicationId: props.selectedApp!.applicationId!,
      ...data,
    };

    dmpClient?.applicationsApi
      .postApplicationsApiUpdateApplicationAttributes(updatedApp)
      .then(() => {
        toast.success("App attributes succesfully updated");
        props.onSubmit();
        reset();
      })
      .catch((err) => {
        props.onSubmit();
        toast.error(
          `Error updating app attribs ${
            err.hasOwnProperty("message") && err.message
          }`
        );
        console.warn(err);
      })
      .finally(() => {});
  };

  return (
    <div className="form-wrapper">
      <div>
        <b>{props.selectedApp!.appName}</b>

        <Field
          label="Image"
          validationState={errors.imageKey ? "error" : "none"}
          validationMessage={errors.imageKey?.message}
        >
          <Select
            //placeholder="select a picture"
            {...register("imageKey", {
              maxLength: 256,
            })}
          >
            <option key={"none"} value={undefined}>
              None
            </option>
            {props.pictograms.map((p) => {
              return (
                <option key={p.imageKey} value={p.imageKey}>
                  {p.imageKey}
                </option>
              );
            })}
          </Select>
          {watchImageKey !== null && (
            <div
              className="pictogram"
              style={{
                backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${watchImageKey}")`,
              }}
            />
          )}
        </Field>

        <Field
          label="Problem Statement"
          required
          validationState={errors.problemStatement ? "error" : "none"}
          validationMessage={
            errors.problemStatement?.type === "maxLength"
              ? "Max length exceeded"
              : undefined
          }
        >
          <Textarea
            {...register("problemStatement", { maxLength: 512 })}
            rows={4}
          />
        </Field>

        <Field
          label="Key Questions"
          required
          validationState={errors.keyQuestions ? "error" : "none"}
          validationMessage={
            errors.keyQuestions?.type === "maxLength"
              ? "Max length exceeded"
              : undefined
          }
        >
          <Textarea
            {...register("keyQuestions", { maxLength: 512 })}
            rows={4}
          />
        </Field>

        <Field
          label="Solution Description"
          required
          validationState={errors.solutionDescription ? "error" : "none"}
          validationMessage={
            errors.solutionDescription?.type === "maxLength"
              ? "Max length exceeded"
              : undefined
          }
        >
          <Textarea
            {...register("solutionDescription", { maxLength: 8000 })}
            rows={6}
          />
        </Field>

        <Field
          label="Differentiated Benefit 1"
          validationState={errors.differentiatedBenefit1 ? "error" : "none"}
          validationMessage={errors.differentiatedBenefit1?.message}
        >
          <Input {...register("differentiatedBenefit1", {})} />
        </Field>

        <Field
          label="Benefit 1 Pictogram"
          validationState={
            errors.differentiatedBenefit1Pictogram ? "error" : "none"
          }
          validationMessage={errors.differentiatedBenefit1Pictogram?.message}
        >
          <Select
            //placeholder="select a picture"
            {...register("differentiatedBenefit1Pictogram", {
              maxLength: 256,
            })}
          >
            <option key={"none"} value={undefined}>
              None
            </option>
            {props.pictograms.map((p) => {
              return (
                <option key={p.imageKey} value={p.imageKey}>
                  {p.imageKey}
                </option>
              );
            })}
          </Select>
          {watchPict1 !== null && (
            <div
              className="pictogram"
              style={{
                backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${watchPict1}")`,
              }}
            />
          )}
        </Field>

        <Field
          label="Differentiated Benefit 2"
          validationState={errors.differentiatedBenefit2 ? "error" : "none"}
          validationMessage={errors.differentiatedBenefit2?.message}
        >
          <Input {...register("differentiatedBenefit2", {})} />
        </Field>

        <Field
          label="Benefit 2 Pictogram"
          validationState={
            errors.differentiatedBenefit2Pictogram ? "error" : "none"
          }
          validationMessage={errors.differentiatedBenefit2Pictogram?.message}
        >
          <Select
            //placeholder="select a picture"
            {...register("differentiatedBenefit2Pictogram", {
              maxLength: 256,
            })}
          >
            <option key={""}>None</option>
            {props.pictograms.map((p) => {
              return (
                <option key={p.imageKey} value={p.imageKey}>
                  {p.imageKey}
                </option>
              );
            })}
          </Select>
          {watchPict2 !== null && (
            <div
              className="pictogram"
              style={{
                backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${watchPict2}")`,
              }}
            />
          )}
        </Field>

        <Field
          label="Differentiated Benefit 3"
          validationState={errors.differentiatedBenefit3 ? "error" : "none"}
          validationMessage={errors.differentiatedBenefit3?.message}
        >
          <Input {...register("differentiatedBenefit3", {})} />
        </Field>

        <Field
          label="Benefit 3 Pictogram"
          validationState={
            errors.differentiatedBenefit3Pictogram ? "error" : "none"
          }
          validationMessage={errors.differentiatedBenefit3Pictogram?.message}
        >
          <Select
            //placeholder="select a picture"
            {...register("differentiatedBenefit3Pictogram", {
              maxLength: 256,
            })}
          >
            <option key={""}>None</option>
            {props.pictograms.map((p) => {
              return (
                <option key={p.imageKey} value={p.imageKey}>
                  {p.imageKey}
                </option>
              );
            })}
          </Select>
          {watchPict3 !== null && (
            <div
              className="pictogram"
              style={{
                backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${watchPict3}")`,
              }}
            />
          )}
        </Field>

        <Field
          label="Key Contact User 1"
          validationState={errors.keyContactId1 ? "error" : "none"}
          validationMessage={errors.keyContactId1?.message}
        >
          <Select
            //placeholder="Key Contact User 1"
            {...register("keyContactId1", {})}
          >
            <option key={"none"} value={""}>None</option>
            {props.users.map((p) => {
              return (
                <option key={p.id} value={p.id}>
                  {p.givenName} {p.familyName}
                </option>
              );
            })}
          </Select>
        </Field>

        <Field
          label="Key Contact User 2"
          validationState={errors.keyContactId2 ? "error" : "none"}
          validationMessage={errors.keyContactId2?.message}
        >
          <Select
            //placeholder="Key Contact User 2"
            {...register("keyContactId2", {})}
          >
            <option key={"none"} value={""}>None</option>
            {props.users.map((p) => {
              return (
                <option key={p.id} value={p.id}>
                  {p.givenName} {p.familyName}
                </option>
              );
            })}
          </Select>
        </Field>

        <Field
          label="Key Contact User 3"
          validationState={errors.keyContactId3 ? "error" : "none"}
          validationMessage={errors.keyContactId3?.message}
        >
          <Select
            //placeholder="Key Contact User 3"
            {...register("keyContactId3", {})}
          >
            <option key={"none"} value={""}>None</option>
            {props.users.map((p) => {
              return (
                <option key={p.id} value={p.id}>
                  {p.givenName} {p.familyName}
                </option>
              );
            })}
          </Select>
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
