import "./WorkspaceDetailsPanel.scss";
import { createTableColumn, TableColumnDefinition, TableCellLayout, Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem } from "@fluentui/react-components";
import { Building24Regular, Document24Regular, Navigation24Regular } from "@fluentui/react-icons";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useMemo, useState } from "react";
import { MessagesStorageStorageDetailItem } from "@arq-apps/dotnet";
import { useSearchParams } from "react-router-dom";
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { ControlledDialog } from "../Dialog/ControlledDialog"
import { toast } from "react-toastify"

export const WorkspaceFilesPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [files, setFiles] = useState<MessagesStorageStorageDetailItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<MessagesStorageStorageDetailItem>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const workspaceId = parseInt(searchParams.get("subscriptionId") ?? "");
  const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");
  
  const fetchFilesList = async () => {
    dmpClient?.storageApi.getStorageApiGetFilesDetailsWorkspace(workspaceId)
      .then((result) => {
        setFiles(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) {
      fetchFilesList()
    }
  }, [isDMPClientLoaded]);

  // const isDeletePermitted = useMemo(() => {
  //   if ((userRole & 2) > 0 || (userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) return true
  //   return false
  // }, [userRole])

  // const deleteEntity = async (entityId: number | undefined) => {
  //   dmpClient?.workspacesApi.deleteWorkspacesApiDeleteEntity(entityId)
  //     .then((result) => {
  //       toast.success("Entity succesfully deleted")
  //       fetchEntitiesList()
  //     })
  //     .catch((err) => {
  //       console.warn(err);
  //       toast.error("Delete entity failed")
  //     })
  //     .finally(() => {});
  // }

  const columns: TableColumnDefinition<MessagesStorageStorageDetailItem>[] = [
    createTableColumn<MessagesStorageStorageDetailItem>({
      columnId: "filename",
      compare: (a, b) => {
        return a.filename.localeCompare(b.filename);
      },
      renderHeaderCell: () => {
        return "Filename";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            media={<Document24Regular color={item.current ? "#D04A02" : "#979797"} />}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.filename}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>{item.entityName}</span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesStorageStorageDetailItem>({
      columnId: "blobName",
      compare: (a, b) => {
        return a.blobName.localeCompare(b.blobName);
      },
      renderHeaderCell: () => {
        return "Blob name";
      },
      renderCell: (item: MessagesStorageStorageDetailItem) => {
        return (
          <TableCellLayout>
            {item.blobName}
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesStorageStorageDetailItem>({
      columnId: "contentType",
      compare: (a, b) => {
        return a.contentType.localeCompare(b.contentType);
      },
      renderHeaderCell: () => {
        return "Content type";
      },
      renderCell: (item: MessagesStorageStorageDetailItem) => {
        return (
          <TableCellLayout>
            {item.contentType}
          </TableCellLayout>
        )
      },
    }),
    //TODO! - @RM do size column
    // createTableColumn<MessagesStorageStorageDetailItem>({
    //   columnId: "actions",
    //   renderHeaderCell: () => {
    //     return "Actions";
    //   },
    //   renderCell: (item) => {
    //     return (
    //       <Menu>
    //         <MenuTrigger disableButtonEnhancement>
    //           <Button icon={<Navigation24Regular/>}/>
    //         </MenuTrigger>
    //         <MenuPopover>
    //           <MenuList>
    //             {isDeletePermitted && <MenuItem onClick={() => {
    //               setSelectedEntity(item)
    //               setIsDeleteDialogOpen(true)
    //             }}>Delete entity</MenuItem>}
    //           </MenuList>
    //         </MenuPopover>
    //       </Menu>
    //     );
    //   },
    // })
  ];

  return (
    <>
      <DataGridComponent
        items={files}
        columns={columns}
        heightAdjustment={90}
        // onAdd={() => setIsAddOpen(true)}
      />
      {/* <ControlledDialog
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        title={"Delete entity"}
        content={"Please confirm that this entity should be removed from the workspace?"}
        onConfirm={() => {
          deleteEntity(selectedEntity?.entityId)
          setIsDeleteDialogOpen(false)
        }}
      /> */}
    </>
  );
};
