import { Button, DrawerBody, DrawerHeader, DrawerHeaderTitle, OverlayDrawer } from "@fluentui/react-components"
import { Dismiss24Regular } from "@fluentui/react-icons"

interface OverlayDrawerComponentProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  title: string,
  content: React.ReactNode,
  size?: "small" | "medium" | "large" | "full"
}

export const OverlayDrawerComponent = (props: OverlayDrawerComponentProps) => {
  return (
    <OverlayDrawer
      as="aside"
      open={props.open}
      position="end"
      onOpenChange={(_, { open }) => props.setOpen(open)}
      size={props.size}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setOpen(false)}
            />
          }
        >
          {props.title}
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        {props.content}
      </DrawerBody>
    </OverlayDrawer>
  )
}
