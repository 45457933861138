/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesApplicationsApplication } from '../models/MessagesApplicationsApplication';
import type { MessagesApplicationsApplicationAccess } from '../models/MessagesApplicationsApplicationAccess';
import type { MessagesApplicationsApplicationAttributes } from '../models/MessagesApplicationsApplicationAttributes';
import type { MessagesApplicationsApplicationRole } from '../models/MessagesApplicationsApplicationRole';
import type { MessagesApplicationsApplicationTeam } from '../models/MessagesApplicationsApplicationTeam';
import type { MessagesApplicationsCreatedApplication } from '../models/MessagesApplicationsCreatedApplication';
import type { MessagesApplicationsNewApplication } from '../models/MessagesApplicationsNewApplication';
import type { MessagesApplicationsNewApplicationRole } from '../models/MessagesApplicationsNewApplicationRole';
import type { MessagesApplicationsPictogram } from '../models/MessagesApplicationsPictogram';
import type { MessagesApplicationsUpdateApplication } from '../models/MessagesApplicationsUpdateApplication';
import type { MessagesApplicationsUser } from '../models/MessagesApplicationsUser';
import type { MessagesSubscriptionsNewOrganisationTeam } from '../models/MessagesSubscriptionsNewOrganisationTeam';
import type { MessagesSubscriptionsOrganisationUser } from '../models/MessagesSubscriptionsOrganisationUser';
import type { MessagesSubscriptionsUpdateOrganisationTeam } from '../models/MessagesSubscriptionsUpdateOrganisationTeam';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApplicationsApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets a list of all applications (Authorised Roles: ProjectMember)
     * @returns MessagesApplicationsApplication OK
     * @throws ApiError
     */
    public getApplicationsApiGetApplications(): CancelablePromise<Array<MessagesApplicationsApplication>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/list',
        });
    }

    /**
     * Creates a new application (Authorised Roles: SuperUser)
     * @param requestBody
     * @returns MessagesApplicationsCreatedApplication OK
     * @throws ApiError
     */
    public putApplicationsApiAddApplication(
        requestBody?: MessagesApplicationsNewApplication,
    ): CancelablePromise<Array<MessagesApplicationsCreatedApplication>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/applications/application',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates an existing application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param requestBody
     * @returns MessagesApplicationsCreatedApplication OK
     * @throws ApiError
     */
    public postApplicationsApiUpdateApplication(
        requestBody?: MessagesApplicationsUpdateApplication,
    ): CancelablePromise<Array<MessagesApplicationsCreatedApplication>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/applications/application',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Makes a user administrator of an application (Authorised Roles: SuperUser)
     * @param applicationId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putApplicationsApiAddApplicationAdmin(
        applicationId?: number,
        adminId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/applications/admins',
            query: {
                'applicationId': applicationId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Removes a users access to administer an application (Authorised Roles: SuperUser)
     * @param applicationId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteApplicationsApiRemoveApplicationAdmin(
        applicationId?: number,
        adminId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/applications/admins',
            query: {
                'applicationId': applicationId,
                'adminId': adminId,
            },
        });
    }

    /**
     * Get a list of roles for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param applicationId
     * @returns MessagesApplicationsApplicationRole OK
     * @throws ApiError
     */
    public getApplicationsApiListApplicationRoles(
        applicationId?: number,
    ): CancelablePromise<Array<MessagesApplicationsApplicationRole>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/roles',
            query: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Add a role for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param requestBody
     * @returns MessagesApplicationsApplicationRole OK
     * @throws ApiError
     */
    public putApplicationsApiAddApplicationRole(
        requestBody?: MessagesApplicationsNewApplicationRole,
    ): CancelablePromise<Array<MessagesApplicationsApplicationRole>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/applications/roles',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Update a role for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param requestBody
     * @returns MessagesApplicationsApplicationRole OK
     * @throws ApiError
     */
    public postApplicationsApiUpdateApplicationRole(
        requestBody?: MessagesApplicationsApplicationRole,
    ): CancelablePromise<Array<MessagesApplicationsApplicationRole>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/applications/roles',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get marketing attributes for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param applicationId
     * @returns MessagesApplicationsApplicationAttributes OK
     * @throws ApiError
     */
    public getApplicationsApiGetApplicationAttributes(
        applicationId?: number,
    ): CancelablePromise<MessagesApplicationsApplicationAttributes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/attribs',
            query: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Update marketing attributes for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param requestBody
     * @returns MessagesApplicationsApplicationAttributes OK
     * @throws ApiError
     */
    public postApplicationsApiUpdateApplicationAttributes(
        requestBody?: MessagesApplicationsApplicationAttributes,
    ): CancelablePromise<MessagesApplicationsApplicationAttributes> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/applications/attribs',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Retrieves the list of application support teams. (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @param appId
     * @returns MessagesApplicationsApplicationTeam OK
     * @throws ApiError
     */
    public getApplicationsApiGetApplicationTeams(
        orgId: number,
        appId: number,
    ): CancelablePromise<Array<MessagesApplicationsApplicationTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/teams',
            query: {
                'orgId': orgId,
                'appId': appId,
            },
        });
    }

    /**
     * Adds a new team to the application. (Authorised Roles: PlatformAdministrator, SuperUser, ApplicationAdministrator)
     * @param requestBody The team to add.
     * @returns MessagesApplicationsApplicationTeam OK
     * @throws ApiError
     */
    public putApplicationsApiAddTeam(
        requestBody?: MessagesSubscriptionsNewOrganisationTeam,
    ): CancelablePromise<Array<MessagesApplicationsApplicationTeam>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/applications/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Updates an application team (Authorised Roles: PlatformAdministrator, SuperUser, ApplicationAdministrator)
     * @param requestBody
     * @returns MessagesApplicationsApplicationTeam OK
     * @throws ApiError
     */
    public postApplicationsApiUpdateTeam(
        requestBody?: MessagesSubscriptionsUpdateOrganisationTeam,
    ): CancelablePromise<Array<MessagesApplicationsApplicationTeam>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/applications/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Gets a list of all image/pictogram keys for use when editing image attributes (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @returns MessagesApplicationsPictogram OK
     * @throws ApiError
     */
    public getApplicationsApiGetPictograms(): CancelablePromise<Array<MessagesApplicationsPictogram>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/pictograms',
        });
    }

    /**
     * Gets a list of all users for use when editing application attributes key contacts (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @returns MessagesApplicationsUser OK
     * @throws ApiError
     */
    public getApplicationsApiGetUsers(): CancelablePromise<Array<MessagesApplicationsUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/users',
        });
    }

    /**
     * Gets a list of all accesses for an application (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param applicationId
     * @returns MessagesApplicationsApplicationAccess OK
     * @throws ApiError
     */
    public getApplicationsApiGetAccesses(
        applicationId?: number,
    ): CancelablePromise<Array<MessagesApplicationsApplicationAccess>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications/accesses',
            query: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Sets the accesses for a role for an application. (Authorised Roles: SuperUser, ApplicationAdministrator)
     * @param applicationId
     * @param roleId
     * @param requestBody
     * @returns MessagesApplicationsApplicationAccess OK
     * @throws ApiError
     */
    public postApplicationsApiSetAccesses(
        applicationId?: number,
        roleId?: number,
        requestBody?: Array<number>,
    ): CancelablePromise<Array<MessagesApplicationsApplicationAccess>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/applications/accesses',
            query: {
                'applicationId': applicationId,
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
