import { MessagesSubscriptionsOrganisationProject } from "@arq-apps/dotnet"
import { Button, createTableColumn, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components"
import { ClipboardTextLtr24Regular, Navigation24Regular } from "@fluentui/react-icons"
import { useEffect, useState } from "react"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent"
import { OrganisationProjectForm } from "../Forms/OrganisationProjectForm"
import { useSearchParams } from "react-router-dom"

export const OrganisationProjectsPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [organisationProjects, setOrganisationProjects] = useState<MessagesSubscriptionsOrganisationProject[]>([]);
  const [editOrganisationProject, setEditOrganisationProject] = useState(false);
  const [addOrganisationProject, setAddOrganisationProject] = useState(false);
  const [selectedOrganisationProject, setSelectedOrganisationProject] = useState<MessagesSubscriptionsOrganisationProject>();

  const orgId = parseInt(searchParams.get("orgId") ?? "");
  // const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");

  const fetchProjectsList = async () => {
    dmpClient?.subscriptionsApi
    .getSubscriptionsApiListOrganisationProjects(orgId)
      .then((result) => {
        setOrganisationProjects(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchProjectsList()
  }, [isDMPClientLoaded])

  const columns: TableColumnDefinition<MessagesSubscriptionsOrganisationProject>[] = [
    createTableColumn<MessagesSubscriptionsOrganisationProject>({
      columnId: "name",
      compare: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            media={<ClipboardTextLtr24Regular color={!item.isFrozen ? "#D04A02" : "#D1D1D1"} />}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.name}</span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationProject>({
      columnId: "isFrozen",
      compare: (a, b) => {
        return a.isFrozen ? 1 : -1;
      },
      renderHeaderCell: () => {
        return "Frozen";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            {item.isFrozen ? "True" : "False"}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationProject>({
      columnId: "workspaceCount",
      compare: (a, b) => {
        return a.workspaceCount - b.workspaceCount
      },
      renderHeaderCell: () => {
        return "Workspace count";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            {item.workspaceCount}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationProject>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular/>}/>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => {
                  setSelectedOrganisationProject(item)
                  setEditOrganisationProject(true)
                }}>Edit project</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    })
  ];

  return (
    <>
      <DataGridComponent 
        items={organisationProjects}
        columns={columns}
        heightAdjustment={90}
        onAdd={() => setAddOrganisationProject(true)}
      />
      <OverlayDrawerComponent
        open={editOrganisationProject}
        setOpen={setEditOrganisationProject}
        title={"Edit organisation project"}
        content={
          <OrganisationProjectForm
            onSubmit={() => setEditOrganisationProject(false)}
            edit
            selectedOrganisationProject={selectedOrganisationProject}
          />
        }
      />
      <OverlayDrawerComponent
        open={addOrganisationProject}
        setOpen={setAddOrganisationProject}
        title={"Add project to organisation"}
        content={
          <OrganisationProjectForm
            onSubmit={() => setAddOrganisationProject(false)}
          />
        }
      />
    </>
  )
}