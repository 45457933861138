import "./ApplicationControl.scss";
import { Logo, UserWidget } from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useMemo, useState } from "react";
import {
  MessagesApplicationsApplicationAttributes,
  MessagesApplicationsApplication,
  MessagesApplicationsPictogram,
  MessagesApplicationsUser,
} from "@arq-apps/dotnet";
import { DataGridComponent } from "../DataGrid/DataGridComponent";
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent";
import {
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Button,
  TableCellLayout,
  SelectTabEvent,
  SelectTabData,
  TabList,
  Tab,
} from "@fluentui/react-components";
import {
  Navigation24Regular,
  PeopleTeam24Regular,
  PersonBoard24Regular,
  AppGeneric24Regular,
  AppGeneric24Filled,
  CheckboxPerson24Regular,
} from "@fluentui/react-icons";
import { AddApplicationForm } from "../Forms/AddApplicationForm";
import { EditApplicationAttribsForm } from "../Forms/ApplicationAttribsForm";
import { useSearchParams } from "react-router-dom";
import { AppRolesPanel } from "./AppRolesPanel";
import { AppAccessesPanel } from "./AppAccessesPanel";
import { AppDetailsPanel } from "./AppDetailsPanel";
import { AppTeamsPanel } from "./AppTeamsPanel";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"

export const ApplicationControl = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [applications, setApplications] = useState<
    MessagesApplicationsApplication[]
  >([]);
  const [pictograms, setPictograms] = useState<MessagesApplicationsPictogram[]>(
    []
  );
  const [users, setUsers] = useState<MessagesApplicationsUser[]>([]);
  const [applicationAttribs, setApplicationAttribs] =
    useState<MessagesApplicationsApplicationAttributes>();
  const [addAppDrawerOpen, setAddAppDrawerOpen] = useState<boolean>(false);
  const [editAppDrawerOpen, setEditAppDrawerOpen] = useState<boolean>(false);
  const [editAttribsDrawerOpen, setEditAttribsDrawerOpen] =
    useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<MessagesApplicationsApplication>();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchApplicationsList = async () => {
    dmpClient?.applicationsApi
      .getApplicationsApiGetApplications()
      .then((result) => {
        setApplications(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchApplicationAttribs = async (appId: number) => {
    dmpClient?.applicationsApi
      .getApplicationsApiGetApplicationAttributes(appId)
      .then((result) => {
        setApplicationAttribs(result);

        if (pictograms.length === 0)
          Promise.all([fetchPictograms(), fetchUsers()]).then(() => {
            setEditAttribsDrawerOpen(true);
          });
        else setEditAttribsDrawerOpen(true);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchPictograms = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetPictograms()
      .then((result) => {
        setPictograms(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchUsers = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetUsers()
      .then((result) => {
        setUsers(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchApplicationsList();
  }, [isDMPClientLoaded]);

  const appId = useMemo(() => {
    return searchParams.get("appId");
  }, [searchParams]);

  const tab = useMemo(() => {
    return searchParams.get("tab");
  }, [searchParams]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    searchParams.set("tab", data.value as string);
    setSearchParams(searchParams);
  };

  const columns: TableColumnDefinition<MessagesApplicationsApplication>[] = [
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "appName",
      compare: (a, b) => {
        return a.appName.localeCompare(b.appName);
      },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            media={
              item.registered ? (
                <AppGeneric24Filled color="#D04A02" />
              ) : (
                <AppGeneric24Regular color="#D1D1D1" />
              )
            }
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.appName}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>
                {item.description}
              </span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "appKey",
      compare: (a, b) => {
        return a.appKey.localeCompare(b.appKey);
      },
      renderHeaderCell: () => {
        return "Key";
      },
      renderCell: (item) => {
        return item.appKey;
      },
    }),
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "status",
      compare: (a, b) => {
        return a.status.localeCompare(b.status);
      },
      renderHeaderCell: () => {
        return "Status";
      },
      renderCell: (item) => {
        return item.status;
      },
    }),
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "roleCount",
      compare: (a, b) => {
        return a.roleCount - b.roleCount;
      },
      renderHeaderCell: () => {
        return "Roles";
      },
      renderCell: (item) => {
        return (
          <>
            {item.roleCount}
            <Button
              appearance="subtle"
              icon={<PersonBoard24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "roles");
                searchParams.set("appId", item.applicationId.toString());
                searchParams.set("appName", item.appName);
                setSearchParams(searchParams);
              }}
            />
          </>
        );
      },
    }),
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "appTeamCount",
      compare: (a, b) => {
        return a.appTeamCount - b.appTeamCount;
      },
      renderHeaderCell: () => {
        return "Teams";
      },
      renderCell: (item) => {
        return (
          <>
            {item.appTeamCount}
            {item.appTeamCount !== item.activeAppTeamCount && (
              <> ({item.activeAppTeamCount} active)</>
            )}
            <Button
              appearance="subtle"
              icon={<PeopleTeam24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "teams");
                searchParams.set("appId", item.applicationId.toString());
                searchParams.set("appName", item.appName);
                setSearchParams(searchParams);
              }}
            />
          </>
        );
      },
    }),
    createTableColumn<MessagesApplicationsApplication>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular />} />
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
              <MenuItem
                  onClick={() => {
                    searchParams.set("tab", "details");
                    searchParams.set("appId", item.applicationId.toString());
                    searchParams.set("appName", item.appName);
                    setSearchParams(searchParams);
                  }}
                >
                  View Application
                </MenuItem>                
                <MenuItem
                  onClick={() => {
                    setSelectedApp(item);
                    setEditAppDrawerOpen(true);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedApp(item);
                    //setEditAttribsDrawerOpen(true);
                    fetchApplicationAttribs(item.applicationId);
                  }}
                >
                  Edit Attributes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    searchParams.set("tab", "accesses");
                    searchParams.set("appId", item.applicationId.toString());
                    searchParams.set("appName", item.appName);
                    setSearchParams(searchParams);
                  }}
                >
                  Edit Accesses
                </MenuItem>

              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    }),
  ];

  return (
    <div className="applications">
      <div className="header">
        <Logo breadcrumbs />
        <UserWidget />
      </div>
      {!appId && !tab && (
        <div className="container">
          <DataGridComponent
            items={applications}
            columns={columns}
            onAdd={() => setAddAppDrawerOpen(true)}
          />
          <OverlayDrawerComponent
            key="addform"
            open={addAppDrawerOpen}
            setOpen={setAddAppDrawerOpen}
            title="Add application"
            content={
              <AddApplicationForm
                onSubmit={() => {
                  setAddAppDrawerOpen(false);
                  fetchApplicationsList();
                }}
              />
            }
          />
          <OverlayDrawerComponent
            key="editform"
            open={editAppDrawerOpen}
            setOpen={setEditAppDrawerOpen}
            title="Edit application"
            content={
              <AddApplicationForm
                edit
                onSubmit={() => {
                  setEditAppDrawerOpen(false);
                  fetchApplicationsList();
                }}
                selectedApp={selectedApp}
              />
            }
          />
          {selectedApp && applicationAttribs && (
            <OverlayDrawerComponent
              key="editattribsform"
              size="medium"
              open={editAttribsDrawerOpen}
              setOpen={setEditAttribsDrawerOpen}
              title="Edit application attributes"
              content={
                <EditApplicationAttribsForm
                  onSubmit={() => {
                    setEditAttribsDrawerOpen(false);
                  }}
                  selectedApp={selectedApp!}
                  applicationAttribs={applicationAttribs!}
                  pictograms={pictograms}
                  users={users}
                />
              }
            />
          )}
        </div>
      )}
      {appId && tab && (
        <div className="container">
          <Breadcrumbs />
          <TabList selectedValue={tab} onTabSelect={onTabSelect}>
            <Tab id="details" icon={<AppGeneric24Regular />} value="details">
              Details
            </Tab>            
            <Tab id="roles" icon={<PersonBoard24Regular />} value="roles">
              Roles
            </Tab>
            <Tab id="accesses" icon={<CheckboxPerson24Regular />} value="accesses">
              Accesses
            </Tab>
            <Tab id="teams" icon={<PersonBoard24Regular />} value="teams">
              Teams
            </Tab>
          </TabList>
          <div style={{ padding: "10px" }}>
            {tab === "details" && <AppDetailsPanel />}
            {tab === "roles" && <AppRolesPanel appId={parseInt(appId)} />}
            {tab === "accesses" && <AppAccessesPanel appId={parseInt(appId)} />}
            {tab === "teams" && <AppTeamsPanel appId={parseInt(appId)} />}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};