/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { ActivityLoggingApiService } from './services/ActivityLoggingApiService';
import { ApplicationsApiService } from './services/ApplicationsApiService';
import { AppSettingsApiService } from './services/AppSettingsApiService';
import { AuthenticationApiService } from './services/AuthenticationApiService';
import { DataAssetApiService } from './services/DataAssetApiService';
import { FaqApiService } from './services/FaqApiService';
import { GlossaryApiService } from './services/GlossaryApiService';
import { LandingApiService } from './services/LandingApiService';
import { QueuesApiService } from './services/QueuesApiService';
import { StorageApiService } from './services/StorageApiService';
import { SubscriptionsApiService } from './services/SubscriptionsApiService';
import { SupportApiService } from './services/SupportApiService';
import { TemplatesApiService } from './services/TemplatesApiService';
import { UiApiService } from './services/UiApiService';
import { WorkspacesApiService } from './services/WorkspacesApiService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class DMPClient {

    public readonly activityLoggingApi: ActivityLoggingApiService;
    public readonly applicationsApi: ApplicationsApiService;
    public readonly appSettingsApi: AppSettingsApiService;
    public readonly authenticationApi: AuthenticationApiService;
    public readonly dataAssetApi: DataAssetApiService;
    public readonly faqApi: FaqApiService;
    public readonly glossaryApi: GlossaryApiService;
    public readonly landingApi: LandingApiService;
    public readonly queuesApi: QueuesApiService;
    public readonly storageApi: StorageApiService;
    public readonly subscriptionsApi: SubscriptionsApiService;
    public readonly supportApi: SupportApiService;
    public readonly templatesApi: TemplatesApiService;
    public readonly uiApi: UiApiService;
    public readonly workspacesApi: WorkspacesApiService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.activityLoggingApi = new ActivityLoggingApiService(this.request);
        this.applicationsApi = new ApplicationsApiService(this.request);
        this.appSettingsApi = new AppSettingsApiService(this.request);
        this.authenticationApi = new AuthenticationApiService(this.request);
        this.dataAssetApi = new DataAssetApiService(this.request);
        this.faqApi = new FaqApiService(this.request);
        this.glossaryApi = new GlossaryApiService(this.request);
        this.landingApi = new LandingApiService(this.request);
        this.queuesApi = new QueuesApiService(this.request);
        this.storageApi = new StorageApiService(this.request);
        this.subscriptionsApi = new SubscriptionsApiService(this.request);
        this.supportApi = new SupportApiService(this.request);
        this.templatesApi = new TemplatesApiService(this.request);
        this.uiApi = new UiApiService(this.request);
        this.workspacesApi = new WorkspacesApiService(this.request);
    }
}

