import { toast } from "react-toastify";
import { useDotnetClient } from "src/contexts/DotnetClientContext";

function killSession(): void {
  // purge local storage & session storage
  sessionStorage.clear();
  window.location.href = window.location.origin;
}

export function useLogout() {
  const { dmpClient } = useDotnetClient();

  return () => {
    if (!dmpClient) return;

    dmpClient.authenticationApi
      .postAuthenticationApiLogout()
      .then((res) => {
        toast.info("You have been logged out successfully");
      })
      .catch((err) => {
        console.error(err);
        toast.warning("Logout failed");
      })
      .finally(() => {
        // if no redirectUri is provided - reload the root platform page
        // window.location.href = data?.logout?.redirectUri ?? window.location.origin;
        setTimeout(() => {
          killSession();
        }, 1000);
      });
  };
}

export function useFlushRedis() {
  const { dmpClient } = useDotnetClient();

  return () => {
    if (!dmpClient) return;
  
    dmpClient.supportApi.getSupportApiFlushRedis()
      .then(() => {
        toast.info("Redis Flushed - You have been logged out.");
      })
      .catch((err) => {
        console.error(err);
        toast.warning("Redis flush failed");
      })
      .finally(() => {
        setTimeout(() => {
          killSession();
        }, 2000);
      });
  };
  
}

export function useSetRedis() {
  const { dmpClient } = useDotnetClient();

  return (key: string, value: string) => {
    if (!dmpClient) return;
  
    dmpClient.supportApi
      .getSupportApiSetRedis(key, value)
      .then(() => {
        toast.info("Redis value set");
      })
      .catch((err) => {
        console.error(err);
        toast.warning("Redis set value failed");
      })
      .finally(() => {});
  };
  
}