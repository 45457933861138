import { MessagesSubscriptionsOrganisationUser } from "@arq-apps/dotnet"
import { Button, createTableColumn, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components"
import { Navigation24Regular, Person24Regular } from "@fluentui/react-icons"
import { useEffect, useMemo, useState } from "react"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent"
import { AddUserToOrgForm } from "../Forms/AddUserToOrgForm"
import { useSearchParams } from "react-router-dom"
import { deriveRolesFromRoleNumber } from "@arq-apps/util"
import { ControlledDialog } from "../Dialog/ControlledDialog"
import { toast } from "react-toastify"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"

export const OrganisationUserPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [organisationUsers, setOrganisationUsers] = useState<MessagesSubscriptionsOrganisationUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<MessagesSubscriptionsOrganisationUser>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isFreezeDialogOpen, setIsFreezeDialogOpen] = useState<boolean>(false);

  const orgId = parseInt(searchParams.get("orgId") ?? "");
  const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");

  const fetchUsersList = async () => {
    dmpClient?.subscriptionsApi
    .getSubscriptionsApiListOrganisationUsers(orgId)
      .then((result) => {
        setOrganisationUsers(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const isDeletePermitted = useMemo(() => {
    if ((userRole & 2) > 0 || (userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) return true
    return false
  }, [userRole])

  const deleteUser = async (userId: number | undefined) => {
    dmpClient?.subscriptionsApi.deleteSubscriptionsApiDeleteUser(userId)
      .then((result) => {
        toast.success("User succesfully deleted")
        fetchUsersList()
      })
      .catch((err) => {
        console.warn(err);
        toast.error("Delete user failed")
      })
      .finally(() => {});
  }

  const freezeUser = async (userId: number | undefined) => {
    dmpClient?.subscriptionsApi.postSubscriptionsApiFreezeUser(userId)
      .then((result) => {
        toast.success("User succesfully frozen")
        fetchUsersList()
      })
      .catch((err) => {
        console.warn(err);
        toast.error("Freeze user failed")
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (isDMPClientLoaded) fetchUsersList()
  }, [isDMPClientLoaded])

  const columns: TableColumnDefinition<MessagesSubscriptionsOrganisationUser>[] = [
    createTableColumn<MessagesSubscriptionsOrganisationUser>({
      columnId: "givenName",
      compare: (a, b) => {
        return a.givenName.localeCompare(b.givenName);
      },
      renderHeaderCell: () => {
        return "Given name";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            media={<Person24Regular color={item.active ? "#D04A02" : "#D1D1D1"} />}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.givenName + " " + item.familyName}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>
                {item.email}
              </span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationUser>({
      columnId: "roles",
      compare: (a, b) => {
        return a.roles - b.roles;
      },
      renderHeaderCell: () => {
        return "Roles";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                flexWrap: "wrap",
                padding: "0.5rem 0"
              }}
            >
              {deriveRolesFromRoleNumber(item.roles).map((role) => {
                return <div style={{width: "min-content", borderRadius: "25px", padding: "0px 10px", backgroundColor: "#D75306", color: "white", whiteSpace: "nowrap"}}>{role}</div>
              })}
            </div>
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationUser>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular/>}/>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => {
                  setSelectedUser(item)
                  setIsEditOpen(true)
                }}>Edit user</MenuItem>
                {isDeletePermitted && <MenuItem onClick={() => {
                  setSelectedUser(item)
                  setIsDeleteDialogOpen(true)
                }}>Delete user</MenuItem>}
                {isDeletePermitted && <MenuItem onClick={() => {
                  setSelectedUser(item)
                  setIsFreezeDialogOpen(true)
                }}>Freeze user</MenuItem>}
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    })
  ];

  return (
    <>
      <DataGridComponent 
        items={organisationUsers}
        columns={columns}
        heightAdjustment={90}
        onAdd={() => setIsAddOpen(true)}
      />
      <OverlayDrawerComponent
        open={isAddOpen}
        setOpen={setIsAddOpen}
        title={"Add user to organisation"}
        content={
          <AddUserToOrgForm
            onSubmit={() => setIsAddOpen(false)}
          />
        }
      />
      <OverlayDrawerComponent
        open={isEditOpen}
        setOpen={setIsEditOpen}
        title={"Edit user"}
        content={
          <AddUserToOrgForm
            onSubmit={() => setIsEditOpen(false)}
            edit
            selectedUser={selectedUser}
          />
        }
      />
      <ControlledDialog 
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        title={"Delete user"}
        content={"Please confirm that the user should be removed from the organisation?"}
        onConfirm={() => {
          deleteUser(selectedUser?.id)
          setIsDeleteDialogOpen(false)
        }}
      />
      <ControlledDialog 
        open={isFreezeDialogOpen}
        setOpen={setIsFreezeDialogOpen}
        title={"Freeze user"}
        content={"Please confirm that the user should be frozen for the organisation?"}
        onConfirm={() => {
          freezeUser(selectedUser?.id)
          setIsFreezeDialogOpen(false)
        }}
      />
    </>
  )
}