import "./WorkspaceDetailsPanel.scss";
import { createTableColumn, TableColumnDefinition, TableCellLayout, Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem } from "@fluentui/react-components";
import { Building24Regular, Navigation24Regular } from "@fluentui/react-icons";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useMemo, useState } from "react";
import { MessagesWorkspacesEntity } from "@arq-apps/dotnet";
import { useSearchParams } from "react-router-dom";
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { ControlledDialog } from "../Dialog/ControlledDialog"
import { toast } from "react-toastify"

export const WorkspaceEntitiesPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [entities, setEntities] = useState<MessagesWorkspacesEntity[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<MessagesWorkspacesEntity>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const workspaceId = parseInt(searchParams.get("subscriptionId") ?? "");
  const userRole:number = parseInt(sessionStorage.getItem('roles') || "1");
  
  const fetchEntitiesList = async () => {
    dmpClient?.workspacesApi.getWorkspacesApiGetOrganisationWorkspaceEntities(workspaceId)
      .then((result) => {
        setEntities(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) {
      fetchEntitiesList()
    }
  }, [isDMPClientLoaded]);

  const isDeletePermitted = useMemo(() => {
    if ((userRole & 2) > 0 || (userRole & 4) > 0 || (userRole & 8) > 0 || (userRole & 16) > 0) return true
    return false
  }, [userRole])

  const deleteEntity = async (entityId: number | undefined) => {
    dmpClient?.workspacesApi.deleteWorkspacesApiDeleteEntity(entityId)
      .then((result) => {
        toast.success("Entity succesfully deleted")
        fetchEntitiesList()
      })
      .catch((err) => {
        console.warn(err);
        toast.error("Delete entity failed")
      })
      .finally(() => {});
  }

  const columns: TableColumnDefinition<MessagesWorkspacesEntity>[] = [
    createTableColumn<MessagesWorkspacesEntity>({
      columnId: "name",
      // compare: (a, b) => {
      //   return a.givenName.localeCompare(b.givenName);
      // },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item: MessagesWorkspacesEntity) => {
        return (
          <TableCellLayout
            media={<Building24Regular color={"#D04A02"} />}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.name}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>{item.guid}</span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesWorkspacesEntity>({
      columnId: "entityTypeName",
      // compare: (a, b) => {
      //   return a.roles - b.roles;
      // },
      renderHeaderCell: () => {
        return "Entity type";
      },
      renderCell: (item: MessagesWorkspacesEntity) => {
        return (
          <TableCellLayout>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                flexWrap: "wrap",
                padding: "0.5rem 0"
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.entityTypeName}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>{item.entityTypeDescription}</span>
            </div>
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesWorkspacesEntity>({
      columnId: "templateFileCount",
      // compare: (a, b) => {
      //   return a.roles - b.roles;
      // },
      renderHeaderCell: () => {
        return "Templates";
      },
      renderCell: (item: MessagesWorkspacesEntity) => {
        return (
          <TableCellLayout>
            {item.templateFileCount}
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesWorkspacesEntity>({
      columnId: "dataAssetFileCount",
      // compare: (a, b) => {
      //   return a.dataAssetFileCount - b.dataAssetFileCount;
      // },
      renderHeaderCell: () => {
        return "Assets";
      },
      renderCell: (item: MessagesWorkspacesEntity) => {
        return (
          <TableCellLayout>
            {item.dataAssetFileCount}
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesWorkspacesEntity>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular/>}/>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {isDeletePermitted && <MenuItem onClick={() => {
                  setSelectedEntity(item)
                  setIsDeleteDialogOpen(true)
                }}>Delete entity</MenuItem>}
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    })
  ];

  return (
    <>
      <DataGridComponent
        items={entities}
        columns={columns}
        heightAdjustment={90}
        // onAdd={() => setIsAddOpen(true)}
      />
      <ControlledDialog
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        title={"Delete entity"}
        content={"Please confirm that this entity should be removed from the workspace?"}
        onConfirm={() => {
          deleteEntity(selectedEntity?.entityId)
          setIsDeleteDialogOpen(false)
        }}
      />
    </>
  );
};
