import "./UserWidget.scss";
import { Icon } from "../../ui/Icon";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { PWC_ID_CONFIG } from "src/const";
import { useLogout, useFlushRedis, useSetRedis } from "src/hooks";
import { useNavigate } from "react-router";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  makeStyles,
} from "@fluentui/react-components";
import { useState } from "react";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
});

export function UserWidget() {
  const loggedIn = sessionStorage.getItem("token") !== null;
  const logout = useLogout();
  const flushRedis = useFlushRedis();
  const setRedis = useSetRedis();

  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = PWC_ID_CONFIG.authUrl();
  };

  const roles: number = parseInt(sessionStorage.getItem("roles") || "1");

  // TODO @RM - move to utils
  const isSuperUser = (roles & 16) > 0;
  const isSubUser =
    (roles & 2) > 0 || (roles & 4) > 0 || (roles & 8) > 0 || (roles & 16) > 0;
  const isImagesUser =
    (roles & 2) > 0 || (roles & 4) > 0 || (roles & 8) > 0 || (roles & 16) > 0;
  const isAppsUser = (roles & 16) > 0 || (roles & 32) > 0;

  const [setRedisDialogOpen, setSetRedisDialogOpen] = useState<boolean>(false);
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  const redisFormSubmit = (e: any) => {
    e.preventDefault();
    setRedis(key, value);
  };

  const styles = useStyles();
  return (
    <div className="user-widget-wrapper">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="user-widget-trigger">
          <div className="userwidget" onClick={handleLogin}>
            <Icon icon="menu-outline" debug="menu-outline" />
            <Icon icon="avatar-outline" debug="avatar-outline" />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="DropdownMenuContent">
            <DropdownMenu.Arrow className="DropdownMenuArrow" />
            {loggedIn && (
              <DropdownMenu.Item className="DropdownMenuItem" onClick={logout}>
                Logout
              </DropdownMenu.Item>
            )}
            {loggedIn && isSubUser && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => navigate("/subscriptions")}
              >
                Subscriptions
              </DropdownMenu.Item>
            )}
            {loggedIn && isAppsUser && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => navigate("/applications")}
              >
                Applications
              </DropdownMenu.Item>
            )}
            {loggedIn && isImagesUser && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => navigate("/images")}
              >
                Image library
              </DropdownMenu.Item>
            )}
            {loggedIn && isSuperUser && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => setSetRedisDialogOpen(true)}
              >
                Set Redis Value
              </DropdownMenu.Item>
            )}
            {loggedIn && isSuperUser && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={flushRedis}
              >
                Flush Redis
              </DropdownMenu.Item>
            )}
            {!loggedIn && (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={handleLogin}
              >
                Login
              </DropdownMenu.Item>
            )}
            {/* <DropdownMenu.Item className="DropdownMenuItem">
              Request Access
            </DropdownMenu.Item> */}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <Dialog modalType="non-modal" open={setRedisDialogOpen} onOpenChange={(e,d) => setSetRedisDialogOpen(d.open)} >
        <DialogSurface aria-describedby={undefined}>
          <form onSubmit={redisFormSubmit}>
            <DialogBody>
              <DialogTitle>Set Redis Value</DialogTitle>
              <DialogContent className={styles.content}>
                <Label required htmlFor={"keyinput"}>
                  Key
                </Label>
                <Input
                  required
                  id={"keyinput"}
                  value={key}
                  onChange={(e, d) => setKey(d.value)}
                />
                <Label required htmlFor={"valueinput"}>
                  Value
                </Label>
                <Input
                  required
                  id={"valueinput"}
                  value={value}
                  onChange={(e, d) => setValue(d.value)}
                />
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => setSetRedisDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button type="submit" appearance="primary">
                  Set Value
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    </div>
  );
}
