import "./SubscriptionControl.scss";
import { Logo, UserWidget } from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useMemo, useState } from "react";
import { MessagesSubscriptionsOrganisationSummary } from "@arq-apps/dotnet";
import { DataGridComponent } from "../DataGrid/DataGridComponent";
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent";
import {
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Button,
  TabList,
  Tab,
  SelectTabEvent,
  SelectTabData,
} from "@fluentui/react-components";
import {
  Navigation24Regular,
  Person24Regular,
  Organization24Regular,
  PeopleTeam24Regular,
  ClipboardTextLtr24Regular,
  Desktop24Regular
} from "@fluentui/react-icons";
import {
  AddOrganisationForm,
} from "../Forms/AddOrganisationForm";
import { AddUserToOrgForm } from "../Forms/AddUserToOrgForm";
import { useSearchParams } from "react-router-dom";
import { AddWorkspaceForm } from "../Forms/AddWorkspaceForm";
import { OrganisationUserPanel } from "./OrganisationUserPanel";
import { OrganisationDetailsPanel } from "./OrganisationDetailsPanel";
import { OrganisationProjectsPanel } from "./OrganisationProjectsPanel";
import { OrganisationTeamsPanel } from "./OrganisationTeamsPanel";
import { OrganisationWorkspacesPanel } from "./OrganisationWorkspacesPanel"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"

export const SubscriptionControl = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [organisations, setOrganisations] = useState<MessagesSubscriptionsOrganisationSummary[]>([]);
  const [addOrgDrawerOpen, setAddOrgDrawerOpen] = useState<boolean>(false);
  const [editOrgDrawerOpen, setEditOrgDrawerOpen] = useState<boolean>(false);
  const [addUserToOrgDrawerOpen, setAddUserToOrgDrawerOpen] = useState<boolean>(false);
  const [addWorkspaceDrawerOpen, setAddWorkspaceDrawerOpen] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<MessagesSubscriptionsOrganisationSummary>();

  const userRole: number = parseInt(sessionStorage.getItem("roles") || "1");

  const fetchOrganisationsList = async () => {
    dmpClient?.subscriptionsApi
      .getSubscriptionsApiGetOrganisations()
      .then((result) => {
        setOrganisations(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchOrganisationsList();
  }, [isDMPClientLoaded]);

  const orgId = useMemo(() => {
    return searchParams.get("orgId");
  }, [searchParams]);

  const tab = useMemo(() => {
    return searchParams.get("tab");
  }, [searchParams]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    searchParams.set("tab", data.value as string);
    setSearchParams(searchParams);
  };

  const columns: TableColumnDefinition<MessagesSubscriptionsOrganisationSummary>[] = [
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "name",
      compare: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout media={<Organization24Regular color={item.active ? "#D04A02" : "#D1D1D1"}/>}>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                gap: "5px",
                lineHeight: "1rem",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.name}</span>
              <span style={{ color: "#979797", fontSize: "small" }}>{item.description}</span>
            </div>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "users",
      compare: (a, b) => {
        return a.users - b.users;
      },
      renderHeaderCell: () => {
        return "Users";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>

            {item.users.toString()}
            {item.usersFrozen !== 0 && (
              <> ({(item.users - item.usersFrozen).toString() + " active)"}</>
            )}

            <Button
              appearance="subtle"
              icon={<Person24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "users");
                searchParams.set("orgId", item.id.toString());
                searchParams.set("orgName", item.name);
                setSearchParams(searchParams);
              }}
            />
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "projects",
      compare: (a, b) => {
        return a.projects - b.projects;
      },
      renderHeaderCell: () => {
        return "Projects";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>

            {item.projects.toString()}
            {item.projectsFrozen !== 0 && (
              <> ({(item.projects - item.projectsFrozen).toString() + " active)"}</>
            )}

            <Button
              appearance="subtle"
              icon={<ClipboardTextLtr24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "projects");
                searchParams.set("orgId", item.id.toString());
                searchParams.set("orgName", item.name);
                setSearchParams(searchParams);
              }}
            />
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "teams",
      compare: (a, b) => {
        return a.projectTeams - b.projectTeams;
      },
      renderHeaderCell: () => {
        return "Teams";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>

            {item.projectTeams.toString()}
            {item.supportTeams !== 0 && (
              <> (+{item.supportTeams.toString()} support)</>
            )}

            <Button
              appearance="subtle"
              icon={<PeopleTeam24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "teams");
                searchParams.set("orgId", item.id.toString());
                searchParams.set("orgName", item.name);
                setSearchParams(searchParams);
              }}
            />
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "workspaces",
      compare: (a, b) => {
        return a.workspaces - b.workspaces;
      },
      renderHeaderCell: () => {
        return "Workspaces";
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>

            {item.workspaces.toString()}
            {item.workspacesFrozen !== 0 && (
              <> ({(item.workspaces - item.workspacesFrozen).toString() + " active)"}</>
            )}

            <Button
              appearance="subtle"
              icon={<Desktop24Regular />}
              onClick={(b) => {
                searchParams.set("tab", "workspaces");
                searchParams.set("orgId", item.id.toString());
                searchParams.set("orgName", item.name);
                setSearchParams(searchParams);
              }}
            />
          </TableCellLayout>
        )
      },
    }),
    createTableColumn<MessagesSubscriptionsOrganisationSummary>({
      columnId: "actions",
      renderHeaderCell: () => {
        return "Actions";
      },
      renderCell: (item) => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button icon={<Navigation24Regular />} />
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setSelectedOrg(item);
                    searchParams.set("tab", "details");
                    searchParams.set("orgId", item.id.toString());
                    setSearchParams(searchParams);
                  }}
                >
                  View organisation
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedOrg(item);
                    setEditOrgDrawerOpen(true);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedOrg(item);
                    setAddUserToOrgDrawerOpen(true);
                  }}
                >
                  Add user
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedOrg(item);
                    setAddWorkspaceDrawerOpen(true);
                  }}
                >
                  Add workspace
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    }),
  ];

  return (
    <div className="subscriptions">
      <div className="header">
        <Logo />
        <UserWidget />
      </div>
      {!orgId && !tab && (
        <div className="container">
          <DataGridComponent
            items={organisations}
            columns={columns}
            onAdd={() => setAddOrgDrawerOpen(true)}
          />
          <OverlayDrawerComponent
            open={addOrgDrawerOpen}
            setOpen={setAddOrgDrawerOpen}
            title="Add organisation"
            content={
              <AddOrganisationForm
                onSubmit={() => setAddOrgDrawerOpen(false)}
              />
            }
          />
          <OverlayDrawerComponent
            open={editOrgDrawerOpen}
            setOpen={setEditOrgDrawerOpen}
            title="Edit organisation"
            content={
              <AddOrganisationForm
                edit
                onSubmit={() => setEditOrgDrawerOpen(false)}
                selectedOrg={selectedOrg}
              />
            }
          />
          <OverlayDrawerComponent
            open={addUserToOrgDrawerOpen}
            setOpen={setAddUserToOrgDrawerOpen}
            title={`Add user to ${selectedOrg?.name ?? "organisation"}`}
            content={
              <AddUserToOrgForm
                onSubmit={() => setAddUserToOrgDrawerOpen(false)}
              />
            }
          />
          <OverlayDrawerComponent
            open={addWorkspaceDrawerOpen}
            setOpen={setAddWorkspaceDrawerOpen}
            title="Add a workspace"
            size="medium"
            content={
              <AddWorkspaceForm
                onSubmit={() => setAddWorkspaceDrawerOpen(false)}
              />
            }
          />
        </div>
      )}
      {orgId && tab && (
        <div className="container">
          <Breadcrumbs />
          <TabList selectedValue={tab} onTabSelect={onTabSelect}>
            <Tab id="Conditions" value="details">
              Details
            </Tab>
            <Tab id="Arrivals" icon={<Person24Regular />} value="users">
              Users
            </Tab>
            <Tab id="projects" icon={<ClipboardTextLtr24Regular />} value="projects">
              Projects
            </Tab>
            <Tab id="teams" icon={<PeopleTeam24Regular />} value="teams">
              Teams
            </Tab>
            <Tab id="workspaces" icon={<Desktop24Regular />} value="workspaces">
              Workspaces
            </Tab>
          </TabList>
          <div style={{ padding: "0px 10px" }}>
            {tab === "details" && <OrganisationDetailsPanel />}
            {tab === "users" && <OrganisationUserPanel />}
            {tab === "projects" && <OrganisationProjectsPanel />}
            {tab === "teams" && <OrganisationTeamsPanel />}
            {tab === "workspaces" && <OrganisationWorkspacesPanel />}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};