import "./Form.scss"
import { useForm } from "react-hook-form";
import {
  Field,
  Input,
  Select,
  Switch,
} from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { MessagesSubscriptionsOrganisationProject, MessagesSubscriptionsProject } from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

interface OrganisationProjectFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedOrganisationProject?: MessagesSubscriptionsOrganisationProject
}

type OrganisationProjectForm = MessagesSubscriptionsOrganisationProject & {
  frequency: string;
}

export const OrganisationProjectForm = (props: OrganisationProjectFormProps) => {
  const { dmpClient } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { 
    register,
    formState: { errors  },
    setError,
    reset,
    handleSubmit
    // control
  } = useForm<OrganisationProjectForm>({
    defaultValues: props.selectedOrganisationProject ?? {
      projectId: undefined,
      name: "",
      isFrozen: false,
      workspaceCount: 0,
      frequency: "Daily"
    },
  });

  const onSubmit = (data: OrganisationProjectForm) => {
    const organisationProject: MessagesSubscriptionsProject = {
      organisationId: parseInt(searchParams.get("orgId") ?? ""),
      id: data.projectId || undefined,
      name: data.name,
      isFrozen: data.isFrozen,
      frequency: data.frequency
    }

    if (props.edit) {
      dmpClient?.subscriptionsApi.postSubscriptionsApiUpdateProject(organisationProject)
      .then((result) => {
        // console.log(result)
        props.onSubmit()
        toast.success("Item succesfully updated")
        reset()
      })
      .catch((err) => {
        props.onSubmit()
        toast.error("Error updating item")
        console.warn(err);
      })
      .finally(() => {});
    } else {
      dmpClient?.subscriptionsApi.putSubscriptionsApiAddProject(organisationProject)
      .then((result) => {
        // console.log(result)
        props.onSubmit()
        toast.success("Item succesfully added")
        reset()
      })
      .catch((err) => {
        props.onSubmit()
        toast.error("Error adding item")
        console.warn(err);
      })
      .finally(() => {});
    }
  }

  return (
    <div className="form-wrapper">
      <div>

        <Field
          label="Name"
          validationState={errors.name ? "error" : "none"}
          validationMessage={errors.name?.message}
          >
          <Input {...register("name", {required: "Name is required", maxLength: 100})} />
        </Field>        

        <Field
          label="Frozen"
          validationState={errors.isFrozen ? "error" : "none"}
          validationMessage={errors.isFrozen?.type === "maxLength" ? "Max length exceeded" : undefined}
        >
          <Switch {...register("isFrozen")}/>
        </Field>

        <Field
          label="Frequency"
          validationState={errors.frequency ? "error" : "none"}
          validationMessage={errors.frequency?.message}
          >
          <Select {...register("frequency")}>
            <option value={"Daily"}>
              Daily
            </option>
            <option value={"Weekly"}>
              Weekly
            </option>
            <option value={"Monthly"}>
              Monthly
            </option>
            <option value={"Annually"}>
              Annually
            </option>
            <option value={"Ad hoc"}>
              Ad hoc
            </option>
          </Select>
        </Field>

        {/* <Field
          label="Workspace count"
          validationState={errors.workspaceCount ? "error" : "none"}
          validationMessage={errors.workspaceCount?.message}
          >
          <Input type="number" {...register("workspaceCount")} />
        </Field> */}
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  )
}