import "./Form.scss";
import { useForm } from "react-hook-form";
import {
  Field,
  Select,
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
  Tag,
  Avatar,
} from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesApplicationsApplication,
  MessagesApplicationsApplicationTeam,
  MessagesSubscriptionsNewOrganisationWorkspace,
  MessagesSubscriptionsNewOrganisationWorkspaceTeam,
  MessagesSubscriptionsOrganisationProject,
  MessagesSubscriptionsOrganisationTeam,
  MessagesSubscriptionsOrganisationWorkspace,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export type WorkspaceForm = {
  projectId?: number;
  applicationId?: number;
};

interface AddWorkspaceFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedWorkspace?: MessagesSubscriptionsOrganisationWorkspace;
  selectedProjectTeams?: MessagesSubscriptionsOrganisationTeam[];
  selectedSupportTeams?: MessagesApplicationsApplicationTeam[];
}

export const AddWorkspaceForm = (props: AddWorkspaceFormProps) => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [projects, setProjects] = useState<MessagesSubscriptionsOrganisationProject[]>([]);
  const [applications, setApplications] = useState<MessagesApplicationsApplication[]>([]);
  const [projectTeams, setProjectTeams] = useState<MessagesSubscriptionsOrganisationTeam[]>([]);
  const [supportTeams, setSupportTeams] = useState<MessagesApplicationsApplicationTeam[]>([]);
  const [selectedProjectTeamOptions, setSelectedProjectTeamOptions] = useState<MessagesSubscriptionsOrganisationTeam[]>(
    (props.edit && props.selectedProjectTeams) ? props.selectedProjectTeams : []
  );
  const [selectedSupportTeamOptions, setSelectedSupportTeamOptions] = useState<MessagesApplicationsApplicationTeam[]>(
    (props.edit && props.selectedSupportTeams) ? props.selectedSupportTeams : []
  );

  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit,
    watch,
    // control
  } = useForm<WorkspaceForm>({
    defaultValues: (props.edit && props.selectedWorkspace) ? {
      projectId: props.selectedWorkspace.projectId,
      applicationId: props.selectedWorkspace.applicationId
    } : {},
  });

  const orgId = parseInt(searchParams.get("orgId") ?? "0");
  const selectedAppId = watch("applicationId");

  const fetchProjects = async () => {
    return dmpClient?.subscriptionsApi
      .getSubscriptionsApiListOrganisationProjects(orgId)
      .then((result) => {
        setProjects(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchApplications = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetApplications()
      .then((result) => {
        setApplications(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchAppTeams = async () => {
    return dmpClient?.applicationsApi
      .getApplicationsApiGetApplicationTeams(orgId, selectedAppId!)
      .then((result) => {
        // setSelectedSupportTeamOptions([]);
        setSupportTeams(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  const fetchProjectTeams = async () => {
    return dmpClient?.subscriptionsApi
      .getSubscriptionsApiGetOrganisationTeams(orgId, selectedAppId!)
      .then((result) => {
        // setSelectedProjectTeamOptions([]);
        setProjectTeams(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded && orgId) {
      if (props.edit) {
        Promise.all([fetchProjects(), fetchApplications(), fetchAppTeams(), fetchProjectTeams()]).then(() => {
          reset();
        })
      } else {
        Promise.all([fetchProjects(), fetchApplications()]).then(() => {
          reset();
        })
      }
    }
  }, [isDMPClientLoaded, orgId]);

  useEffect(() => {
    if (
      isDMPClientLoaded &&
      orgId &&
      selectedAppId &&
      (selectedAppId as any) !== ""
    ) {
      fetchAppTeams();
      fetchProjectTeams();
    } else {
      setSelectedSupportTeamOptions([]);
      setSelectedProjectTeamOptions([]);
    }
    reset();
  }, [selectedAppId]);

  const projectOnOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    setSelectedProjectTeamOptions(
      projectTeams.filter((x) => data.selectedOptions.includes(x.id.toString()))
    );
  };
  const tagPickerProjectTeamOptions = projectTeams.filter(
    (option) => !selectedProjectTeamOptions.includes(option)
  );

  const supportOnOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    setSelectedSupportTeamOptions(
      supportTeams.filter((x) => data.selectedOptions.includes(x.id.toString()))
    );
  };
  const tagPickerSupportTeamOptions = supportTeams.filter(
    (option) => !selectedSupportTeamOptions.includes(option)
  );

  const onSubmit = (data: WorkspaceForm) => {
    const newWorkspace: MessagesSubscriptionsNewOrganisationWorkspace = {
      projectId: data.projectId!,
      applicationId: data.applicationId!,
    };

    dmpClient?.subscriptionsApi
      .putSubscriptionsApiAddOrganisationWorkspaces(newWorkspace)
      .then((result) => {
        toast.success("Workspace succesfully added");

        const subscriptionId = result.subscriptionId;
        const teamsToAdd: Promise<void>[] = [];

        selectedProjectTeamOptions.forEach((team) => {
          const newWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
            {
              subscriptionId: subscriptionId,
              teamId: team.id,
            };

          const newTeamRequest = dmpClient?.workspacesApi
            .putWorkspacesApiAddProjectTeamToWorkspace(newWorkspaceTeam)
            .then((r) => {
              toast.success(`Project Team ${team.name} succesfully added`);
            });

          teamsToAdd.push(newTeamRequest);
        });

        selectedSupportTeamOptions.forEach((team) => {
          const newWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
            {
              subscriptionId: subscriptionId,
              teamId: team.id,
            };

          const newTeamRequest = dmpClient?.workspacesApi
            .putWorkspacesApiAddSupportTeamToWorkspace(newWorkspaceTeam)
            .then((r) => {
              toast.success(`Support Team ${team.name} succesfully added`);
            });

          teamsToAdd.push(newTeamRequest);
        });

        Promise.all(teamsToAdd)
          .then((res) => {})
          .catch((err) => {
            toast.error("Error adding teams to workspace");
            console.warn(err);
          });
      })
      .catch((err) => {
        toast.error("Error adding workspace");
        console.warn(err);
      })
      .finally(() => {
        reset();
        props.onSubmit();
      });
  };

  const onEditSubmit = () => {
    const subscriptionId = props.selectedWorkspace?.subscriptionId!
    const teamsToAdd: Promise<void>[] = [];
    const teamsToRemove: Promise<void>[] = [];

    const addedProjectTeams = selectedProjectTeamOptions.filter(current => !props.selectedProjectTeams?.find((original) => original.id === current.id));
    const removedProjectTeams = props.selectedProjectTeams?.filter(original => !selectedProjectTeamOptions.find((current) => current.id === original.id)) ?? [];
    
    const addedSupportTeams = selectedSupportTeamOptions.filter(current => !props.selectedSupportTeams?.find((original) => original.id === current.id));
    const removedSupportTeams = props.selectedSupportTeams?.filter(original => !selectedSupportTeamOptions.find((current) => current.id === original.id)) ?? [];

    addedProjectTeams.forEach((team) => {
      const newWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
        {
          subscriptionId: subscriptionId,
          teamId: team.id,
        };

      const newTeamRequest = dmpClient?.workspacesApi
        .putWorkspacesApiAddProjectTeamToWorkspace(newWorkspaceTeam)
        .then((r) => {
          toast.success(`Project Team ${team.name} succesfully added`);
        });
      
      if (newTeamRequest) teamsToAdd.push(newTeamRequest);
    });

    addedSupportTeams.forEach((team) => {
      const newWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
        {
          subscriptionId: subscriptionId,
          teamId: team.id,
        };

      const newTeamRequest = dmpClient?.workspacesApi
        .putWorkspacesApiAddSupportTeamToWorkspace(newWorkspaceTeam)
        .then((r) => {
          toast.success(`Support Team ${team.name} succesfully added`);
        });

      if (newTeamRequest) teamsToAdd.push(newTeamRequest);
    });

    removedProjectTeams?.forEach((team) => {
      const removedWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
        {
          subscriptionId: subscriptionId,
          teamId: team.id,
        };

      const removeTeamRequest = dmpClient?.workspacesApi
        .deleteWorkspacesApiDeleteProjectTeamFromWorkspace(removedWorkspaceTeam)
        .then((r) => {
          toast.success(`Project Team ${team.name} succesfully removed`);
        });
      
      if (removeTeamRequest) teamsToRemove.push(removeTeamRequest);
    });

    removedSupportTeams?.forEach((team) => {
      const removedWorkspaceTeam: MessagesSubscriptionsNewOrganisationWorkspaceTeam =
        {
          subscriptionId: subscriptionId,
          teamId: team.id,
        };

      const removeTeamRequest = dmpClient?.workspacesApi
        .deleteWorkspacesApiDeleteSupportTeamFromWorkspace(removedWorkspaceTeam)
        .then((r) => {
          toast.success(`Support Team ${team.name} succesfully removed`);
        });
      
      if (removeTeamRequest) teamsToRemove.push(removeTeamRequest);
    });

    Promise.all(teamsToAdd.concat(teamsToRemove))
      .then((res) => {})
      .catch((err) => {
        toast.error("Error adding teams to workspace");
        console.warn(err);
      });
  }

  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit(props.edit ? onEditSubmit : onSubmit)}>
        <div>
          <Field
            required
            label="Project"
            validationState={errors.projectId ? "error" : "none"}
            validationMessage={errors.projectId?.message}
          >
            <Select
              disabled={props.edit}
              //placeholder="Project"
              {...register("projectId", {
                required: "Project is required",
                maxLength: 256,
              })}
            >
              <option key={"-"} value={undefined}></option>
              {projects.map((p) => {
                return (
                  <option key={p.projectId} value={p.projectId}>
                    {p.name}
                  </option>
                );
              })}
            </Select>
          </Field>

          <Field
            required
            label="Application"
            validationState={errors.applicationId ? "error" : "none"}
            validationMessage={errors.applicationId?.message}
          >
            <Select
              disabled={props.edit}
              //placeholder="Application"
              {...register("applicationId", {
                required: "Application is required",
                maxLength: 256,
              })}
            >
              <option key={"-"} value={undefined}></option>
              {applications.map((a) => {
                return (
                  <option key={a.applicationId} value={a.applicationId}>
                    {a.appName}
                  </option>
                );
              })}
            </Select>
          </Field>

          <Field label="Project team/s">
            <TagPicker
              onOptionSelect={projectOnOptionSelect}
              selectedOptions={selectedProjectTeamOptions.map((x) =>
                x.id.toString()
              )}
              disabled={(selectedAppId as any) == ""}
            >
              <TagPickerControl>
                <TagPickerGroup>
                  {selectedProjectTeamOptions.map((option) => (
                    <Tag
                      key={option.id}
                      shape="rounded"
                      media={
                        <Avatar
                          aria-hidden
                          name={option.name}
                          color="colorful"
                        />
                      }
                      value={option.id.toString()}
                    >
                      {option.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput aria-label="Select Teams" />
              </TagPickerControl>
              <TagPickerList>
                {tagPickerProjectTeamOptions.length > 0
                  ? tagPickerProjectTeamOptions.map((option) => (
                      <TagPickerOption
                        secondaryContent="Project Team"
                        media={
                          <Avatar
                            shape="square"
                            aria-hidden
                            name={option.name}
                            color="colorful"
                          />
                        }
                        value={option.id.toString()}
                        key={option.id}
                      >
                        {option.name}
                      </TagPickerOption>
                    ))
                  : "No project teams available"}
              </TagPickerList>
            </TagPicker>
          </Field>

          <Field label="Support team/s">
            <TagPicker
              onOptionSelect={supportOnOptionSelect}
              selectedOptions={selectedSupportTeamOptions.map((x) =>
                x.id.toString()
              )}
              disabled={(selectedAppId as any) == ""}
            >
              <TagPickerControl>
                <TagPickerGroup>
                  {selectedSupportTeamOptions.map((option) => (
                    <Tag
                      key={option.id}
                      shape="rounded"
                      media={
                        <Avatar
                          aria-hidden
                          name={option.name}
                          color="colorful"
                        />
                      }
                      value={option.id.toString()}
                    >
                      {option.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput aria-label="Select Teams" />
              </TagPickerControl>
              <TagPickerList>
                {tagPickerSupportTeamOptions.length > 0
                  ? tagPickerSupportTeamOptions.map((option) => (
                      <TagPickerOption
                        secondaryContent="Support Team"
                        media={
                          <Avatar
                            shape="square"
                            aria-hidden
                            name={option.name}
                            color="colorful"
                          />
                        }
                        value={option.id.toString()}
                        key={option.id}
                      >
                        {option.name}
                      </TagPickerOption>
                    ))
                  : "No support teams available"}
              </TagPickerList>
            </TagPicker>
          </Field>
        </div>
        <div className="form-action-container">
          <Button text="Submit" />
        </div>
      </form>
    </div>
  );
};
