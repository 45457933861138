import {
  Badge,
  Button,
  Checkbox,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  SearchBox,
  TableCellLayout,
  TableColumnDefinition,
  ToggleButton,
} from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useFooterHeight } from "src/hooks";

interface AppAccessesPanelProps {
  appId: number;
}

export type ApplicationAccessItem = {
  accessId: number;
  nodeType: string;
  nodeId: string;
  nodeDescription: string;
};

type TAccessMap = Map<number, ApplicationAccessItem>;
type TRoleMap = Map<number, ApplicationAccessRole>;
type TRoleAccessMap = Map<number, Map<number, boolean>>;

export type ApplicationAccessRole = {
  roleId: number;
  roleName: string;
  roleDescription: string;
};

type TNodeTypeFilter = {
  count: number;
  nodeType: string;
};

export const AppAccessesPanel = (props: AppAccessesPanelProps) => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [search, setSearch] = useState<string>("");
  const footerHeight = useFooterHeight();
  // const [applicationAccesses, setApplicationAccesses] = useState<
  //   MessagesApplicationsApplicationAccess[]
  // >([]);
  const [applicationAccessList, setApplicationAccessList] = useState<
    ApplicationAccessItem[]
  >([]);
  const [columns, setColumns] = useState<
    TableColumnDefinition<ApplicationAccessItem>[]
  >([]);
  const [checkedMap, setCheckedMap] = useState<TRoleAccessMap>(
    new Map<number, Map<number, boolean>>()
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [nodeTypeFilters, setNodeTypeFilters] = useState<TNodeTypeFilter[]>([]);
  const [activeNodeTypeFilters, setActiveNodeTypeFilters] = useState<string[]>(
    []
  );

  const fetchRolesList = async () => {
    dmpClient?.applicationsApi
      .getApplicationsApiGetAccesses(props.appId)
      .then((result) => {
        const rolesMap: TRoleMap = new Map<number, ApplicationAccessRole>();
        const rolesAccessCountMap = new Map<number, number>();
        const accessMap: TAccessMap = new Map<number, ApplicationAccessItem>();
        const accessAccessCountMap = new Map<number, number>();
        const newCheckedMap: Map<number, Map<number, boolean>> = new Map<
          number,
          Map<number, boolean>
        >();
        const nodeTypeFilters = new Map<string, number>();

        result.forEach((x) => {
          // cols
          if (
            x.roleId !== undefined &&
            x.roleId !== null &&
            !rolesMap.has(x.roleId!)
          )
            rolesMap.set(x.roleId!, {
              roleId: x.roleId,
              roleName: x.roleName!,
              roleDescription: x.roleDescription!,
            });

          // checks
          if (
            x.roleId !== undefined &&
            x.roleId !== null &&
            x.id !== undefined
          ) {
            const checkedAccessMap =
              newCheckedMap.get(x.roleId) ?? new Map<number, boolean>();
            checkedAccessMap.set(x.id!, x.accessGranted ?? false);
            newCheckedMap.set(x.roleId, checkedAccessMap);

            if (x.accessGranted == true) {
              const currentChecks = rolesAccessCountMap.get(x.roleId);
              rolesAccessCountMap.set(x.roleId, (currentChecks ?? 0) + 1);

              const currentChecksAccess = accessAccessCountMap.get(x.id);
              accessAccessCountMap.set(x.id, (currentChecksAccess ?? 0) + 1);
            }
          }

          // rows
          if (
            x.id !== undefined &&
            x.nodeId !== undefined &&
            x.nodeId !== null &&
            x.nodeType !== undefined &&
            x.nodeType !== null
          )
            if (!accessMap.has(x.id)) {
              if (!nodeTypeFilters.has(x.nodeType))
                nodeTypeFilters.set(x.nodeType, 1);
              else
                nodeTypeFilters.set(
                  x.nodeType,
                  nodeTypeFilters.get(x.nodeType)! + 1
                );

              accessMap.set(x.id!, {
                accessId: x.id!,
                nodeDescription: x.description ?? "",
                nodeId: x.nodeId,
                nodeType: x.nodeType!,
              });
            }
        });

        setCheckedMap(newCheckedMap);

        setNodeTypeFilters(
          Array.from(nodeTypeFilters)
            .sort((a, b) => b[1] - a[1])
            .map((x) => {
              return {
                nodeType: x[0],
                count: x[1],
              } as TNodeTypeFilter;
            })
        );

        // constant columns
        const newColumns: TableColumnDefinition<ApplicationAccessItem>[] = [
          createTableColumn<ApplicationAccessItem>({
            columnId: "name",
            compare: (a, b) => {
              return a.nodeId.localeCompare(b.nodeId);
            },
            renderHeaderCell: () => {
              return "Node Id";
            },
            renderCell: (item) => {
              return (
                <TableCellLayout>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      paddingTop: ".5rem",
                      paddingBottom: ".5rem",
                      gap: "5px",
                      lineHeight: "1rem",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>{item.nodeId}</span>
                    <span style={{ color: "#979797", fontSize: "small" }}>
                      {item.nodeDescription}
                    </span>
                  </div>
                </TableCellLayout>
              );
            },
          }),
          createTableColumn<ApplicationAccessItem>({
            columnId: "nodeType",
            compare: (a, b) => {
              return a.nodeType.localeCompare(b.nodeType);
            },
            renderHeaderCell: () => {
              return "Type";
            },
            renderCell: (item) => {
              return item.nodeType;
            },
          }),
        ];

        // dynamic columns for roles
        const rolesArray = Array.from(rolesMap.values()).sort(
          (a, b) =>
            (rolesAccessCountMap.get(b.roleId) ?? 0) -
            (rolesAccessCountMap.get(a.roleId) ?? 0)
        );

        rolesArray.forEach((r) => {
          newColumns.push(
            createTableColumn<ApplicationAccessItem>({
              columnId: r.roleId.toString(),
              // compare: (a, b) => {
              //   return a.nodeType.localeCompare(b.nodeType);
              // },
              renderHeaderCell: () => {
                return (
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      paddingTop: ".5rem",
                      paddingBottom: ".5rem",
                      gap: "5px",
                      lineHeight: "1rem",
                    }}
                  >
                    <span>{r.roleName}</span>
                    <span
                      style={{
                        color: "#979797",
                        fontSize: "small",
                        fontWeight: "400",
                      }}
                    >
                      {r.roleDescription}
                    </span>
                  </div>
                );
              },
              renderCell: (item) => {
                return (
                  <Checkbox
                    checked={
                      newCheckedMap.has(r.roleId) &&
                      newCheckedMap.get(r.roleId)?.get(item.accessId)
                    }
                    onChange={(e, d) => {
                      const newChecked = new Map<number, Map<number, boolean>>(
                        newCheckedMap
                      );
                      newChecked
                        .get(r.roleId!)
                        ?.set(item.accessId, d.checked as boolean);
                      setCheckedMap(newChecked);
                    }}
                  />
                );
              },
            })
          );
        });

        setColumns(newColumns);

        // rows
        const accessList = Array.from(accessMap.values()).sort((a, b) => {
          const cmpCounts =
            (accessAccessCountMap.get(b.accessId) ?? 0) -
            (accessAccessCountMap.get(a.accessId) ?? 0);
          return cmpCounts == 0 ? b.nodeId.localeCompare(a.nodeId) : cmpCounts;
        });

        setApplicationAccessList(accessList);
        // setApplicationAccesses(result);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => setLoaded(true));
  };

  const saveAccessSelections = () => {
    setSaving(true);
    const proms: Promise<void>[] = [];
    checkedMap.forEach((v, k) => {
      const accessesForRole = Array.from(v)
        .filter((x) => x[1] == true)
        .map((x) => x[0]);

      const prom = dmpClient?.applicationsApi
        .postApplicationsApiSetAccesses(props.appId, k, accessesForRole)
        .then(() => {})
        .catch((err) => {
          toast.error(`Error settings accesses for role ${k}`);
          console.warn(err);
        });
      proms.push(prom!);
    });

    Promise.all(proms)
      .then(() => {
        toast.success("Saved all roles");
      })
      .catch((err) => {
        toast.error("Error settings accesses for roles");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchRolesList();
  }, [isDMPClientLoaded]);

  const filteredItems = useMemo(() => {
    return applicationAccessList.filter(
      (item) =>
        (activeNodeTypeFilters.length === 0 ||
          activeNodeTypeFilters.includes(item.nodeType)) &&
        item.nodeId.includes(search.toLowerCase())
    );
  }, [search, applicationAccessList, activeNodeTypeFilters]);

  const getRowId = (item: ApplicationAccessItem) => {
    return item.accessId;
  };

  if (!loaded) return <>Loading...</>;

  return (
    <div className="container-roles">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            marginBottom: "20px",
            gap: ".5rem",
          }}
        >
          {nodeTypeFilters.map((n) => {
            return (
              <ToggleButton
                size="small"
                shape="circular"
                defaultChecked={false}
                onClick={(e) => {
                  const current = [...activeNodeTypeFilters];
                  if (current.includes(n.nodeType)) {
                    const newFilters = current.filter((x) => x !== n.nodeType);
                    setActiveNodeTypeFilters(newFilters);
                  } else {
                    current.push(n.nodeType);
                    setActiveNodeTypeFilters(current);
                  }
                }}
              >
                {n.nodeType}: {n.count}
              </ToggleButton>
            );
          })}
          <SearchBox
            value={search}
            onChange={(e, data) => setSearch(data.value)}
          />
        </div>

        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div>
            <Badge appearance="outline">{filteredItems.length} items</Badge>
          </div>
          {
            <Button disabled={saving} onClick={saveAccessSelections}>
              Save
            </Button>
          }
        </div>

        <DataGrid
          items={filteredItems}
          columns={columns}
          sortable
          getRowId={getRowId}
          style={{ backgroundColor: "white" }}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell style={{ fontWeight: "bold" }}>
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<ApplicationAccessItem>
            style={{
              maxHeight: `calc(100vh - ${footerHeight}px - 250px - ${180}px)`,
              overflowY: "auto",
            }}
          >
            {({ item, rowId }) => (
              <DataGridRow<ApplicationAccessItem> key={rowId}>
                {({ renderCell }) => (
                  <DataGridCell>{renderCell(item)}</DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      </div>
    </div>
  );
};
