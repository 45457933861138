import "./Form.scss";
import { useForm } from "react-hook-form";
import {
  Field,
  Input,
  Textarea,
  Select
} from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesApplicationsApplication,
  MessagesApplicationsNewApplication,
  MessagesApplicationsUpdateApplication,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";

export type ApplicationForm = {
  name: string;
  appKey: string;
  description: string;
  status: string;
  maxFileSizeMb: number;
  fileType: string;
};

interface AddApplicationFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedApp?: MessagesApplicationsApplication;
}

export const AddApplicationForm = (props: AddApplicationFormProps) => {
  const { dmpClient } = useDotnetClient();
  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit,
    // control
  } = useForm<MessagesApplicationsApplication>({
    defaultValues: props.selectedApp ?? {
          appName: "",
          appKey: "",
          description: "",
          status: "Developing",
          maxFileSizeMb: 50,
          fileTypes: "*.csv/*.xlsx",
          applicationId: 0,
        } as MessagesApplicationsApplication,
  });

  const onSubmit = (data: MessagesApplicationsApplication) => {
    if (props.edit) {
      const updatedApp: MessagesApplicationsUpdateApplication = {
        id: props.selectedApp!.applicationId!,
        name: data.appName,
        fileType: data.fileTypes,
        ...data,
      };

      dmpClient?.applicationsApi
        .postApplicationsApiUpdateApplication(updatedApp)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("App succesfully updated");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error updating app");
          console.warn(err);
        })
        .finally(() => {});
    } else {
      const newApp: MessagesApplicationsNewApplication = {
        name: data.appName,
        fileType: data.fileTypes,
        ...data,
      };

      dmpClient?.applicationsApi
        .putApplicationsApiAddApplication(newApp)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("App succesfully added");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error adding app");
          console.warn(err);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Key"
          validationState={errors.appKey ? "error" : "none"}
          validationMessage={errors.appKey?.message}
        >
          <Input
            {...register("appKey", {
              required: "Key is required",
              maxLength: 256,
            })}
          />
        </Field>
        <Field
          label="Name"
          required
          validationState={errors.appName ? "error" : "none"}
          validationMessage={errors.appName?.message}
        >
          <Input
            {...register("appName", {
              required: "Name is required",
              maxLength: 256,
            })}
          />
        </Field>
        <Field
          label="Description"
          validationState={errors.description ? "error" : "none"}
          validationMessage={
            errors.description?.type === "maxLength"
              ? "Max length exceeded"
              : undefined
          }
        >
          <Textarea {...register("description", { maxLength: 256 })} rows={4} />
        </Field>
        <Field
          label="Status"
          validationState={errors.status ? "error" : "none"}
          validationMessage={errors.status?.message}
        >
          <Select 
            //placeholder="Status"
            {...register("status", {
              required: "Status is required",
              maxLength: 256,
            })}
          >
            <option key={"Developing"}>Developing</option>
            <option key={"Coming Soon"}>Coming Soon</option>
            <option key={"Live"}>Live</option>
          </Select >
        </Field>
        <Field
          label="Max File Size (MB)"
          validationState={errors.maxFileSizeMb ? "error" : "none"}
          validationMessage={errors.maxFileSizeMb?.message}
        >
          <Input {...register("maxFileSizeMb", {})} type="number" />
        </Field>

        <Field
          label="File Types"
          validationState={errors.fileTypes ? "error" : "none"}
          validationMessage={errors.fileTypes?.message}
        >
          <Input {...register("fileTypes", { maxLength: 128 })} />
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
