import "./Form.scss";
import { useForm } from "react-hook-form";
import { Field, Input, Select } from "@fluentui/react-components";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import {
  MessagesSubscriptionsNewOrganisationTeam,
  MessagesSubscriptionsOrganisationSummary,
  MessagesApplicationsApplicationTeam,
  MessagesSubscriptionsUpdateOrganisationTeam,
} from "@arq-apps/dotnet";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

interface ApplicationTeamFormProps {
  onSubmit: () => void;
  edit?: boolean;
  selectedOrganisationTeam?: MessagesApplicationsApplicationTeam;
  appId: number;
}

type TApplicationTeamForm = {
  id?: number;
  name: string;
  description: string;
  appId: number;
  orgId?: number;
};

export const ApplicationTeamForm = (props: ApplicationTeamFormProps) => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [organisations, setOrganisations] = useState<
    MessagesSubscriptionsOrganisationSummary[]
  >([]);

  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit,
    // control
  } = useForm<TApplicationTeamForm>({
    defaultValues: props.edit == true
      ? ({
          appId: props.appId,
          id: props.selectedOrganisationTeam!.id,
          name: props.selectedOrganisationTeam!.name,
          description: props.selectedOrganisationTeam!.description,
          orgId: props.selectedOrganisationTeam!.organisationId,
        } as TApplicationTeamForm)
      : ({
          appId: props.appId,
          name: "",
          description: "",
          orgId: 1,
        } as TApplicationTeamForm),
  });

  const fetchOrganisations = async () => {
    return dmpClient?.subscriptionsApi
      .getSubscriptionsApiGetOrganisations()
      .then((result) => {
        setOrganisations(result);
        reset();
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isDMPClientLoaded) {
      fetchOrganisations();
    }
  }, [isDMPClientLoaded]);

  const onSubmit = (data: TApplicationTeamForm) => {

    if (props.edit) {
      const updatedTeam: MessagesSubscriptionsUpdateOrganisationTeam = {
        ...data,
        orgId: data.orgId!,
        appId: props.appId,
        id: data.id!,
      };

      dmpClient?.applicationsApi
        .postApplicationsApiUpdateTeam(updatedTeam)
        .then((result) => {
          // console.log(result)
          toast.success("Item succesfully updated");
          props.onSubmit();
          reset();
        })
        .catch((err) => {
          console.warn(err);
          toast.error("Error updating item");
          props.onSubmit();
        })
        .finally(() => {});
    } else {
      const newTeam: MessagesSubscriptionsNewOrganisationTeam = {
        ...data,
        orgId: data.orgId!,
        appId: props.appId,
      };

      dmpClient?.applicationsApi
        .putApplicationsApiAddTeam(newTeam)
        .then((result) => {
          // console.log(result)
          props.onSubmit();
          toast.success("Item succesfully added");
          reset();
        })
        .catch((err) => {
          props.onSubmit();
          toast.error("Error adding item");
          console.warn(err);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="form-wrapper">
      <div>
        <Field
          label="Name"
          required
          validationState={errors.name ? "error" : "none"}
          validationMessage={errors.name?.message}
        >
          <Input
            {...register("name", {
              required: "Name is required",
              maxLength: 100,
            })}
          />
        </Field>

        <Field
          label="Description"
          validationState={errors.description ? "error" : "none"}
          validationMessage={errors.description?.message}
        >
          <Input {...register("description", { maxLength: 200 })} />
        </Field>

        <Field
          required
          label="Organisation"
          validationState={errors.orgId ? "error" : "none"}
          validationMessage={errors.orgId?.message}
        >
          <Select
            disabled={props.edit}
            //placeholder="Application"
            {...register("orgId", {
              required: "Organisation is required",
            })}
          >
            <option key={"none"} value={undefined}></option>
            {organisations.map((o) => {
              return (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              );
            })}
          </Select>
        </Field>
      </div>
      <div className="form-action-container">
        <Button text="Submit" onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};
