/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesApplicationsApplicationTeam } from '../models/MessagesApplicationsApplicationTeam';
import type { MessagesSubscriptionsNewOrganisationWorkspaceTeam } from '../models/MessagesSubscriptionsNewOrganisationWorkspaceTeam';
import type { MessagesSubscriptionsOrganisationTeam } from '../models/MessagesSubscriptionsOrganisationTeam';
import type { MessagesSubscriptionsOrganisationWorkspace } from '../models/MessagesSubscriptionsOrganisationWorkspace';
import type { MessagesWorkspacesEntity } from '../models/MessagesWorkspacesEntity';
import type { MessagesWorkspacesUpdateEntity } from '../models/MessagesWorkspacesUpdateEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkspacesApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param workspaceId
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public getWorkspacesApiGetProjectTeamsWorkspace(
        workspaceId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/workspaces/projectTeam',
            query: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putWorkspacesApiAddProjectTeamToWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/workspaces/projectTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteWorkspacesApiDeleteProjectTeamFromWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/workspaces/projectTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param workspaceId
     * @returns MessagesApplicationsApplicationTeam OK
     * @throws ApiError
     */
    public getWorkspacesApiGetSupportTeamsWorkspace(
        workspaceId?: number,
    ): CancelablePromise<Array<MessagesApplicationsApplicationTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/workspaces/supportTeam',
            query: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putWorkspacesApiAddSupportTeamToWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/workspaces/supportTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteWorkspacesApiDeleteSupportTeamFromWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/workspaces/supportTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param workspaceId
     * @returns MessagesWorkspacesEntity OK
     * @throws ApiError
     */
    public getWorkspacesApiGetOrganisationWorkspaceEntities(
        workspaceId?: number,
    ): CancelablePromise<Array<MessagesWorkspacesEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/workspaces/entities',
            query: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesWorkspacesEntity OK
     * @throws ApiError
     */
    public postWorkspacesApiUpdateEntity(
        requestBody?: MessagesWorkspacesUpdateEntity,
    ): CancelablePromise<Array<MessagesWorkspacesEntity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/workspaces/entities',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param entityId
     * @returns MessagesWorkspacesEntity OK
     * @throws ApiError
     */
    public deleteWorkspacesApiDeleteEntity(
        entityId?: number,
    ): CancelablePromise<Array<MessagesWorkspacesEntity>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/workspaces/entities',
            query: {
                'entityId': entityId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param entityId
     * @returns MessagesWorkspacesEntity OK
     * @throws ApiError
     */
    public deleteWorkspacesApiDeleteEntityFiles(
        entityId?: number,
    ): CancelablePromise<Array<MessagesWorkspacesEntity>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/workspaces/entities/files',
            query: {
                'entityId': entityId,
            },
        });
    }

}
