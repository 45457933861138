import "./OrganisationDetailsPanel.scss"
import { Avatar } from "@fluentui/react-components"
import { Edit24Regular } from "@fluentui/react-icons"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { OverlayDrawerComponent } from "../OverlayDrawerComponent/OverlayDrawerComponent"
import { useEffect, useState } from "react"
import { AddOrganisationForm } from "../Forms/AddOrganisationForm"
import { MessagesSubscriptionsOrganisationSummary } from "@arq-apps/dotnet"
import { useSearchParams } from "react-router-dom"

export const OrganisationDetailsPanel = () => {
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [organisation, setOrganisation] = useState<MessagesSubscriptionsOrganisationSummary>();
  const [editOrgOpen, setEditOrgOpen] = useState(false);
  const orgId = parseInt(searchParams.get("orgId") ?? "");  

  const fetchOrganisation = async () => {
    const result = await dmpClient?.subscriptionsApi.getSubscriptionsApiGetOrganisation(orgId)
    setOrganisation(result)
  };

  useEffect(() => {
    if (isDMPClientLoaded) fetchOrganisation()
  }, [isDMPClientLoaded])

  return (
    <div className="organisation-card">
      <Avatar name={organisation?.name} size={64} color={"colorful"}/>
      <div className="organisation-content">
        <div style={{fontSize: "20px", fontWeight: "800"}}>{organisation?.name}</div>
        <div style={{fontSize: "18px"}}>{organisation?.longName}</div>
        <div style={{fontSize: "16px"}}>{organisation?.description}</div>
        <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
          <div style={{width: "min-content", borderRadius: "25px", padding: "0px 10px", backgroundColor: "#D75306", color: "white"}}>
            {organisation?.active ? "Active" : "Inactive"}
          </div>
          <div style={{width: "min-content", borderRadius: "25px", padding: "0px 10px", backgroundColor: "#D75306", color: "white"}}>
            {organisation?.whitelistedDomain}
          </div>
        </div>
      </div>
      <Edit24Regular onClick={() => setEditOrgOpen(true)}/>
      <OverlayDrawerComponent 
        open={editOrgOpen}
        setOpen={setEditOrgOpen}
        title="Edit organisation details"
        content={<AddOrganisationForm
          onSubmit={() => setEditOrgOpen(false)} 
          edit
          selectedOrg={organisation}
        />}
      />
    </div>
  )
}
